import React, { useEffect, useState } from "react";
import CasinoSlots from "../casinopage/CasinoSlots";
import { useSelector } from "react-redux";

const CasinoEvents = () => {
  const [activeEvent, setActiveEvent] = useState(0);
  const [Categories, setCategories] = useState([{ name: "all" }]);
  const Casino = useSelector((state) => state.Casino.events);
  useEffect(() => {
    if (Categories.length == 1) {
      if (Casino?.categories?.length > 0) {
        setCategories((prevCategories) => {
          const newCategories = Casino?.categories?.filter(
            (category) => !prevCategories.includes(category)
          );
          return [...prevCategories, ...newCategories];
        });
      }
    }
  }, [Casino]);
  return (
    <div>
      <div className="container pb-4">
        <div className="overflow-auto events-scollbar">
          {Categories?.length > 1 ? (
            <div className="events-div d-flex align-items-center cursor-pointer justify-content-between gap-3">
              {Categories?.map((event, index) => (
                <div
                  key={index}
                  className={`d-flex align-items-center gap-2 px-3 ${
                    activeEvent === index ? "active-event" : "events"
                  }`}
                  onClick={() => setActiveEvent(index)}
                >
                  <p className="mb-0 ff_heebo fw-medium fs_14 text-white text-nowrap text-uppercase">
                    {event.name}
                  </p>
                </div>
              ))}
            </div>
          ) : (
            <div className="d-flex gap-3 overflow-hidden">
              <div
                className="skeleton"
                style={{
                  height: "35px",
                  width: "120px",
                  marginTop: "12px",
                  borderRadius: "50px",
                }}
              ></div>
              <div
                className="skeleton"
                style={{
                  height: "35px",
                  width: "120px",
                  marginTop: "12px",
                  borderRadius: "50px",
                }}
              ></div>
              <div
                className="skeleton"
                style={{
                  height: "35px",
                  width: "120px",
                  marginTop: "12px",
                  borderRadius: "50px",
                }}
              ></div>
            </div>
          )}
        </div>
      </div>
      <CasinoSlots
        event={Casino?.games}
        Categorie={Categories}
        active={activeEvent}
      />
    </div>
  );
};

export default CasinoEvents;
