import React, { useEffect, useState } from "react";
import cricket from "../../assets/images/svg/cricketStumps.svg";
import arrowRight from "../../assets/images/svg/arrowRight.svg";
import mi from "../../assets/images/png/mumbaiIndians.png";
import dc from "../../assets/images/png/delhiCapitals.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import BetSlip from "../bottomnav/BetSlip";
import { useDispatch, useSelector } from "react-redux";
import {
  GetDateDescription,
  getTodayDate,
  TimePending,
} from "../../Services/Common";
import { setEvent } from "../../redux/SingleEvents/SelectedEvent";
import { GetOtherEvents } from "../../redux/Cricket/CircketSlice";
import { GetSportById } from "../../Services/SportsRadar";
import Skeletion from "../skeletion/Skeletion";
import axios from "axios";
import VsLogo from "../../assets/images/png/versus.png";
const EventsToBet = () => {
  const [Matches, setMatches] = useState([]);
  const dispatch = useDispatch();
  const [selectGame, setSelectGame] = useState([]);
  const { id } = useParams();
  const [currentGame, setCurrentGame] = useState("");
  const navigate = useNavigate();
  const [isCricket, setIsCriket] = useState(true);
  const [isBetting, setIsBetting] = useState(false);
  if (!isBetting) {
    document.body.style.overflow = "visible";
  } else {
    document.body.style.overflow = "hidden";
  }

  async function OtherMatches() {
    const data = {
      sport_id: id,
      date: await getTodayDate(),
    };
    await GetSportById(data).then((res) => {
      console.log("mathes", res);
      setCurrentGame(res[0]?.sport_name);
      setMatches(res);
    });
  }

  const TimePending2 = (d) => {
    const now = new Date();
    const target = new Date(d);
    const diff = target - now;
    if (diff < 0) {
      return "Live";
    }
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    return `${days} days ${hours} hours ${minutes} minutes`;
  };
  const getCirkcet = async () => {
    const res = await axios({
      url: process.env.REACT_APP_BASEURL + "/getCricketMatch",
      method: "GET",
    });

    console.log("res ===============>", res);
    setMatches(res.data.data);
  };

  useEffect(() => {
    if (id == "sr:sport:21") {
      getCirkcet();
    } else {
      setIsCriket(false);
      OtherMatches();
    }
  }, []);
  return (
    <div className="ps-3 pb-3 position-relative">
      <div className="container ps-0">
        <div className="mt-4">
          <div className="d-flex align-items-center gap-2">
            <img src={cricket} alt="stumps" />
            <p className="mb-0 ff_inter fs_19 text-white fw-medium">
              {currentGame}
            </p>
          </div>
        </div>
        <div>
          {isCricket && (
            <div className="row overflow-auto matches-box-div">
              {Matches?.length > 0 ? (
                Matches?.filter(
                  (value) => TimePending(value?.event?.startDate) !== "ENDED"
                )?.map((value, index) => (
                  <div className="col-12 col-lg-4 py-2">
                    <div
                      key={index}
                      className="matches-div rounded-2 px-3 py-4 h-100 mt-3"
                      onClick={() => {
                        dispatch(setEvent(value));
                        navigate(
                          `/sport_details/${value?.sports_id}/circket/${value?.match_start_time}/${value?.betfair_event_id}`
                        );
                      }}
                    >
                      <div>
                        <div className="d-flex justify-content-between">
                          <p className="text-white mb-0 ff_inter fw-medium fs_12">
                            {value?.match_name}
                          </p>
                          <div className="text-center d-flex gap-3">
                            {TimePending2(value?.match_start_time) !==
                              "Live" && (
                              <p className="mb-0 text-white ff_inter fs_11 fw-medium">
                                Start in
                              </p>
                            )}
                            {TimePending2(value?.match_start_time) == "Live" ? (
                              <p className="mb-0 bg-danger text-white px-2 rounded ff_inter fs_11 fw-bold">
                                {value.status == "closed"
                                  ? "Ended"
                                  : TimePending2(value?.match_start_time)}
                              </p>
                            ) : (
                              <p className="mb-0 clr_green ff_inter fs_11 fw-bold">
                                {value.status == "closed"
                                  ? "Ended"
                                  : TimePending(value?.match_start_time)}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="events-hr-line mt-3"></div>
                        <div className="d-flex align-items-center justify-content-between mt-4 ">
                          <div className="text-center team-width">
                            <img
                              style={{ width: "48px" }}
                              src={mi}
                              alt="mumbaiIndians"
                            />
                            <p className="mb-0 text-white ff_inter fs_11 fw-medium mt-3">
                              {value?.team1_name}
                            </p>
                          </div>
                          <div>
                            <img
                              style={{ width: "48px" }}
                              src={VsLogo}
                              alt="mumbaiIndians"
                            />
                          </div>
                          <div className="text-center team-width">
                            <img
                              style={{ width: "30px" }}
                              src={dc}
                              alt="delhiCapitals"
                            />
                            <p className="mb-0 text-white ff_inter fs_11 fw-medium mt-3">
                              {value?.team2_name}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex mt-3 gap-1"></div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="mt-3 d-flex flex-column justify-content-center gap-2 align-items-center overflow-hidden">
                  <Skeletion
                    height={"210px"}
                    width={"350px"}
                    borderRadius={5}
                  />
                </div>
              )}
            </div>
          )}
          {!isCricket && (
            <div>
              {Matches?.length > 0 ? (
                <div className="row overflow-auto matches-box-div">
                  {Matches?.length > 0 ? (
                    Matches?.map((value, index) => (
                      <div className="col-12 col-lg-4 py-2">
                      <div
                        key={index}
                        className="matches-div rounded-2 px-3 py-4 h-100 mt-3"
                        onClick={() => {
                          dispatch(setEvent(value));
                          navigate(
                            `/sport_details/${value?.id}/${value.sport_name}`
                          );
                        }}
                      >
                        <div>
                          <div className="d-flex justify-content-between">
                            <p className="text-white mb-0 ff_inter fw-medium fs_12">
                              {value?.match_name}
                            </p>
                            <div className="text-center d-flex gap-3">
                              {TimePending2(value?.next_live_time) !==
                                "Live" && (
                                <p className="mb-0 text-white ff_inter fs_11 fw-medium">
                                  Start in
                                </p>
                              )}
                              {TimePending2(value?.next_live_time) == "Live" ? (
                                <p className="mb-0 bg-danger text-white px-2 rounded ff_inter fs_11 fw-bold">
                                  {value.status == "closed"
                                    ? "Ended"
                                    : TimePending2(value?.next_live_time)}
                                </p>
                              ) : (
                                <p className="mb-0 clr_green ff_inter fs_11 fw-bold">
                                  {value.status == "closed"
                                    ? "Ended"
                                    : TimePending(value?.next_live_time)}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="events-hr-line mt-3"></div>
                          <div className="d-flex align-items-center justify-content-between mt-4 ">
                            <div className="text-center team-width">
                              <img
                                style={{ width: "48px" }}
                                src={mi}
                                alt="mumbaiIndians"
                              />
                              <p className="mb-0 text-white ff_inter fs_11 fw-medium mt-3">
                                {value?.team1_name}
                              </p>
                            </div>
                            <div>
                              <img
                                style={{ width: "48px" }}
                                src={VsLogo}
                                alt="mumbaiIndians"
                              />
                            </div>
                            <div className="text-center team-width">
                              <img
                                style={{ width: "30px" }}
                                src={dc}
                                alt="delhiCapitals"
                              />
                              <p className="mb-0 text-white ff_inter fs_11 fw-medium mt-3">
                                {value?.team2_name}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex mt-3 gap-1"></div>
                      </div>
                      </div>
                    ))
                  ) : (
                    <div className="mt-3 d-flex flex-column justify-content-center gap-2 align-items-center overflow-hidden">
                      <Skeletion
                        height={"210px"}
                        width={"350px"}
                        borderRadius={5}
                      />
                    </div>
                  )}

                  <div
                    onClick={() => setIsBetting(false)}
                    className={`body-overlay ${
                      isBetting === true ? "d-block" : "d-none"
                    }`}
                  ></div>
                  <BetSlip
                    active={isBetting}
                    setIsBettingFalse={() => setIsBetting(false)}
                  />
                </div>
              ) : (
                <div className="mt-3 d-flex gap-2">
                  <Skeletion height={180} width={290} borderRadius={5} />
                </div>
              )}
            </div>
          )}
          <div
            onClick={() => setIsBetting(false)}
            className={`body-overlay ${
              isBetting === true ? "d-block" : "d-none"
            }`}
          ></div>
          <BetSlip
            active={isBetting}
            setIsBettingFalse={() => setIsBetting(false)}
          />
        </div>
      </div>
    </div>
  );
};

export default EventsToBet;
