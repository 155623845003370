import axios from "axios";
const Url = process.env.REACT_APP_BASEURL;
export const LoginWithNumber = async (data) => {
  const url = Url + "/loginWithNumber";
  const res = await axios({ url, method: "POST", data });
  if (res.data.status) {
    return true;
  } else {
    return false;
  }
};

export const NumberOtpVerify = async (number, otp) => {
  const data = {
    phone: number,
    otp: otp,
  };
  const url = Url + "/verifyOtpOnNumber";
  const res = await axios({ url, method: "POST", data });

  if (res.data.status) {
    return res.data;
  } else {
    return false;
  }
};

export const EmailOtpVerify = async (email, otp) => {
  const data = {
    email: email,
    otp: otp,
  };
  const url = Url + "/verifyOtpOnMail";
  const res = await axios({ url, method: "POST", data });

  if (res.data.status) {
    return res.data;
  } else {
    return false;
  }
};

export const LoginWithEmail = async (data) => {
  const url = Url + "/loginWithMail";
  const res = await axios({ url, method: "POST", data });
  if (res.data.status) {
    return true;
  } else {
    return false;
  }
};
export const SaveUser = async (data) => {
  const url = Url + "/updateDeviceId";
  const res = await axios({
    url,
    method: "POST",
    headers: {
      Authorization: localStorage.getItem("sfwa_tkn"),
    },
    data,
  });
  if (res.data.status) {
    return true;
  } else {
    return false;
  }
};

export const LoginWithGoogle = async (data) => {
  const url = Url + "/loginWithSocial";
  const res = await axios({ url, method: "POST", data });
  if (res.data.status) {
    return res.data;
  } else {
    return res.data;
  }
};
export const getMe = async () => {
  const url = Url + "/getUser";
  const token = await localStorage.getItem("sfwa_tkn");
  try {
    const res = await axios({
      url: url,
      headers: {
        authorization: token,
      },
    });
    if (res.status == 200) {
      return res.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const oneClickLoginCreate = async (data) => {
  const url = Url + "/oneclickLogin";
  const res = await axios({ url, method: "get" });
  if (res.data.status) {
    return res.data;
  } else {
    return false;
  }
};

export const loginWithPasword = async (data) => {
  const url = Url + "/CustomLogin";
  const res = await axios({ url, method: "POST", data });
  if (res.data.status) {
    return res.data;
  } else {
    return false;
  }
};

export const resentOTP = async (data) => {
  const url = Url + "/resendOtp";
  const res = await axios({ url, method: "POST", data });
  if (res.data.status) {
    return true;
  } else {
    return false;
  }
};

export const SaveUserDetails = async (data) => {
  const url = Url + "/saveInfo";
  const res = await axios({
    url,
    method: "POST",
    headers: {
      Authorization: localStorage.getItem("sfwa_tkn"),
    },
    data,
  });
  if (res.data.status) {
    return true;
  } else {
    return false;
  }
};
