// import React, { useEffect, useState } from "react";
// import { claimBonus, GetAllBonus } from "../../Services/Transactions";
// import { getLocalDate } from "../../Services/Common";
// import ProgressBar from "react-bootstrap/ProgressBar";

// function Bouns() {
//     const baseUrl = process.env.REACT_APP_BASEURL;
//     const [allBouns, setAllBouns] = useState([]);

//     const getBouns = async () => {
//         await GetAllBonus().then(res => {
//             setAllBouns(res);
//         });
//     };

//     const handleClaim = async (id) => {
//         console.log(id)

//         const data = {
//             bonus_id: id
//         }
//         claimBonus(data).then((res) => {
//             console.log(res)
//             getBouns()
//         })
//     }

//     useEffect(() => {
//         getBouns();
//     }, []);

//     return (
//         <div className="container py-3">
//             <div className="">
//                 <h5 className="fs_16 fw-medium ff_heebo text-white">Bonus History</h5>
//                 <div>
//                     {allBouns?.map((item, index) => {
//                         const progress = (item?.your_wagering / item?.target_wagering) * 100;
//                         return (
//                             <div key={index} className="ps-2 pe-2 py-2 ff_heebo text-white bg_clr_dark_grey_3 rounded-1 border-0 w-100 mt-3 d-flex justify-content-between align-items-center">
//                                 <div className="d-flex w-100 justify-content-between">
//                                     <div className="d-flex w-100">
//                                         <div>
//                                             <img src={baseUrl + "/image/" + item?.groupImage} height={70} width={70} alt={item?.name} />
//                                         </div>
//                                         <div className="ps-3">
//                                             <p className="fs_16 fw-medium ff_heebo text-white text-uppercase mb-0">{item.name}</p>
//                                             <p className="fs_10 fw-medium ff_heebo clr-grey mb-0 mt-1 text-nowrap">Given Date : {getLocalDate(item.createdAt)}</p>
//                                             <div className="d-flex align-items-center">
//                                                 <ProgressBar variant="success" now={progress} className="range" />
//                                                 <div className="ps-3">
//                                                     <span>{item?.your_wagering}/{item?.target_wagering}</span>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                     <div className="">
//                                         <div className="d-flex flex-column text-end">
//                                             <span className="fs_12 fw-medium ff_heebo text-white">{item?.bonus_amount} pts</span>
//                                             <span className={`p-2 ${item?.status === "complete" ? "bg-success" : item?.status === "pending" ? "bg-warning" : "bg-danger"} text-white rounded mt-2 text-nowrap`}
//                                                 onClick={() => {
//                                                     item?.status === "complete" && handleClaim(item?._id)
//                                                 }}
//                                             >
//                                                 {!item?.status === "complete" ? item?.status : item?.bonus_clamed ? "claimed" : "Claim now" }</span>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         );
//                     })}
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default Bouns;

import React, { useEffect, useState } from "react";
import { claimBonus, GetAllBonus } from "../../Services/Transactions";
import { getLocalDate } from "../../Services/Common";
import ProgressBar from "react-bootstrap/ProgressBar";

function Bouns() {
  const baseUrl = process.env.REACT_APP_BASEURL;
  const [allBouns, setAllBouns] = useState([]);

  const getBouns = async () => {
    await GetAllBonus().then((res) => {
      setAllBouns(res);
    });
  };

  const handleClaim = async (id) => {
    console.log(id);

    const data = {
      bonus_id: id,
    };
    claimBonus(data).then((res) => {
      console.log(res);
      getBouns();
    });
  };

  useEffect(() => {
    getBouns();
  }, []);

  return (
    <div className="container py-3">
      <div className="">
        <h5 className="fs_16 fw-medium ff_heebo text-white">Bonus History</h5>
        <div>
          {allBouns.length > 0 ? (
            allBouns.map((item, index) => {
              const progress =
                (item?.your_wagering / item?.target_wagering) * 100;
              return (
                <div
                  key={index}
                  className="ps-2 pe-2 py-2 ff_heebo text-white bg_clr_dark_grey_3 rounded-1 border-0 w-100 mt-3 d-flex justify-content-between align-items-center"
                >
                  <div className="d-flex w-100 justify-content-between">
                    <div className="d-flex w-100">
                      <div>
                        <img
                          src={baseUrl + "/image/" + item?.groupImage}
                          height={70}
                          width={70}
                          alt={item?.name}
                        />
                      </div>
                      <div className="ps-3">
                        <p className="fs_16 fw-medium ff_heebo text-white text-uppercase mb-0">
                          {item.name}
                        </p>
                        <p className="fs_10 fw-medium ff_heebo clr-grey mb-0 mt-1 text-nowrap">
                          Given Date : {getLocalDate(item.createdAt)}
                        </p>
                        <div className="d-flex align-items-center">
                          <ProgressBar
                            variant="success"
                            now={progress}
                            className="range"
                          />
                          <div className="ps-3">
                            <span>
                              {item?.your_wagering}/{item?.target_wagering}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <div className="d-flex flex-column text-end">
                        <span className="fs_12 fw-medium ff_heebo text-white">
                          {item?.bonus_amount} pts
                        </span>
                        <span
                          className={`p-2 ${
                            item?.status === "complete"
                              ? "bg-success"
                              : item?.status === "pending"
                              ? "bg-warning"
                              : "bg-danger"
                          } text-white rounded mt-2 text-nowrap`}
                          onClick={() => {
                            item?.status === "complete" &&
                              handleClaim(item?._id);
                          }}
                        >
                          {!item?.status === "complete"
                            ? item?.status
                            : item?.bonus_clamed
                            ? "claimed"
                            : "Claim now"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <h4 className="fs_16  fw-medium ff_heebo text-white text-center mt-5">
              No Bonus Found
            </h4>
          )}
        </div>
      </div>
    </div>
  );
}

export default Bouns;
