import React from "react";
import "../components/home/Home.css";
import HomeHero from "../components/home/HomeHero";
import Categories from "../components/home/Categories";
import Events from "../components/home/Events";
import Appfooter from "../components/footer/Appfooter";

const HomePage = () => {
  return (
    <div className="">
      <HomeHero />
      <Categories />
      <Events />
      <Appfooter />
    </div>
  );
};

export default HomePage;
