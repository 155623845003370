import axios from "axios";
const baseUrl = process.env.REACT_APP_BASEURL;
export const uploadScreenShot = async (data) => {
  const res = await axios({
    url: baseUrl + "/add_money",
    method: "POST",
    headers: {
      Authorization: localStorage.getItem("sfwa_tkn"),
    },
    data,
  });
  console.log(res);
  if (res.data.data.status) {
    return true;
  } else {
    return false;
  }
};
export const WidthDrawlRequest = async (data) => {
  const res = await axios({
    url: baseUrl + "/withdrawl_money",
    method: "POST",
    headers: {
      Authorization: localStorage.getItem("sfwa_tkn"),
    },
    data,
  });
  console.log(res);
  if (res.data.status) {
    return true;
  } else {
    return false;
  }
};

export const AddBank = async (data) => {
  const res = await axios({
    url: baseUrl + "/add_bank",
    method: "POST",
    headers: {
      Authorization: localStorage.getItem("sfwa_tkn"),
    },
    data,
  });
  if (res.data.status) {
    return true;
  } else {
    return false;
  }
};

export const GetBank = async (data) => {
  const res = await axios({
    url: baseUrl + "/get_bank",
    method: "POST",
    headers: {
      Authorization: localStorage.getItem("sfwa_tkn"),
    },
    data,
  });
  if (res.data.status) {
    return res.data.data;
  } else {
    return [];
  }
};
export const GetDeleteBank = async (id) => {
  const res = await axios({
    url: baseUrl + "/delete_bank/" + id,
    method: "GET",
    headers: {
      Authorization: localStorage.getItem("sfwa_tkn"),
    },
  });
  console.log(res);
  if (res.data.status) {
    return true;
  } else {
    return false;
  }
};
