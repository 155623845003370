import React, { useEffect, useState } from "react";
import AppNavbar from "../components/home/AppNavbar";
import HomeHero from "../components/home/HomeHero";
import NewHomeGameOpction from "../components/newhomepage/NewHomeGameOpction";
import Categories from "../components/home/Categories";
import "../components/newhomepage/newhomepage.css";
import BottomNav from "../components/bottomnav/BottomNav";
import LetsChat from "../components/home/LetsChat";
import Appfooter from "../components/footer/Appfooter";
import LiveSports from "../components/newhomepage/LiveSports";
import CasinoSlots from "../components/casinopage/CasinoSlots";
import { useDispatch, useSelector } from "react-redux";
import { GetAllCasnio } from "../redux/Casino/CasinoSlice";
import { GetCricketEvents } from "../redux/Cricket/CircketSlice";
import CricketEvents from "../components/home/CricketEvents";

// import crypto from "crypto-browserify"; // Updated to use crypto-browserify
import WelcomeBonus from "../components/newhomepage/WelcomeBonus";

import Intercom from "@intercom/messenger-js-sdk";

const NewHomePage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetAllCasnio());
    // dispatch(GetCricketEvents());
  }, []);

  const [activeEvent, setActiveEvent] = useState(0);
  const [casinoCategories, setCasinoCategories] = useState([{ name: "all" }]);
  const Casino = useSelector((state) => state.Casino.events);

  useEffect(() => {
    if (casinoCategories.length === 1) {
      if (Casino?.categories?.length > 0) {
        setCasinoCategories((prevCategories) => {
          const newCategories = Casino?.categories?.filter(
            (category) => !prevCategories.includes(category)
          );
          return [...prevCategories, ...newCategories];
        });
      }
    }
  }, [Casino]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Generating user hash for Intercom Identity Verification
  // const secretKey = "tCvehNWAc8bY62ukHZiuNeQh00EZexYue0gkx2nX"; // Your Intercom Identity Verification secret key
  // const userIdentifier = "current_user_id"; // Replace with your user identifier

  // const hash = crypto
  //   .createHmac("sha256", secretKey)
  //   .update(userIdentifier)
  //   .digest("hex");

  // Initialize Intercom with user details
  Intercom({
    app_id: "lr6fz8wc", // Replace with your Intercom app ID
    user_id: "3453453463463453453", // Replace with your user ID
    name: "Mandeep Lamba", // Replace with user name
    email: "mandeeeplamba43@gmail.com", // Replace with user email
    // user_hash: hash, // Hashed user identity
  });

  return (
    <div>
      <HomeHero />
      <NewHomeGameOpction />
      <Categories />
      <LiveSports />
      <CasinoSlots
        event={Casino?.games}
        Categorie={casinoCategories}
        active={activeEvent}
        Home={true}
      />
      <WelcomeBonus />
      <CricketEvents />
      <Appfooter />
    </div>
  );
};

export default NewHomePage;
