import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import cricket from "../../assets/images/svg/cricketStumps.svg";
import { useDispatch, useSelector } from "react-redux";
import EventsInfo from "../betting/EventsInfo.";
import socketService from "../../Services/SocketService";
import axios from "axios";
import SportsRadarBettingModal from "../betting/SportsRadarBettingModal";
import { addSportsRadarBet } from "../../redux/User/UserSlice";
import { toast } from "react-toastify";

function OtherSportsEventDetails() {
  const { sport, id } = useParams();
  const SelectedEvent = useSelector((state) => state.SelectedEvent.events);
  const [match, setMatch] = useState();
  const [previousMatch, setPreviousMatch] = useState(null); // Store previous match data
  const [rippleIndexes, setRippleIndexes] = useState({}); // Track ripple state for each outcome
  const [BettingModal, setBettingModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [betLock, setBetLock] = useState(false);
  const dispatch = useDispatch();
  const GetStaticData = async () => {
    setLoading(true);
    const res = await axios({
      url: process.env.REACT_APP_BASEURL + "/getRedarSportsOdds",
      method: "POST",
      data: {
        match_id: id,
      },
    });
    console.log("res ====>", res.data);
    setMatch(res.data.data);
    setLoading(false);
  };

  useEffect(() => {
    if (id) {
      socketService.connect();

      socketService.emit("join", { userId: id }, (error, message) => {
        if (error) {
          console.log(`Error joining: ${error}`);
        } else {
          console.log(message);
        }
      });

      const handleBookmark = (data) => {
        console.log(data);
        setBetLock(false);
        // Compare new match data with the previous one
        if (JSON.stringify(data) !== JSON.stringify(previousMatch)) {
          setPreviousMatch(match); // Store the current match as the previous one
          setMatch(data); // Update the match with the new data
          triggerRippleEffect(data); // Trigger ripple effect when match data changes
        }
      };

      socketService.on("sportsradar", handleBookmark);
      socketService.on("sr_bet_stop", (data) => {
        console.log("first");
        console.log(data);
        setBetLock(true);
      });
      return () => {
        socketService.disconnect();
      };
    }
  }, [id, match, previousMatch, socketService]);

  const triggerRippleEffect = (newMatch) => {
    if (newMatch?.markets) {
      const newRippleIndexes = {};

      newMatch.markets.forEach((market, marketIndex) => {
        market.outcomes.forEach((outcome, outcomeIndex) => {
          newRippleIndexes[`${marketIndex}-${outcomeIndex}`] = true;
        });
      });

      setRippleIndexes(newRippleIndexes);

      // Remove ripple effect after animation duration
      setTimeout(() => {
        setRippleIndexes({});
      }, 600); // 600ms matches the CSS animation duration
    }
  };

  useEffect(() => {
    if (id) {
      GetStaticData();
    }
  }, [id]);

  function getTwoWords(text) {
    const words = text.split(" "); // Split the string into an array of words
    if (words.length > 2) {
      return words.slice(0, 2).join(" ") + "..."; // Return the first two words with "..."
    }
    return text; // If there are 2 or fewer words, return the original text
  }
  return (
    <div className="">
      <div className="container ">
        <div className="row ">
          <div
            style={{ top: "73px" }}
            className="col-12 ps-0 ps-lg-2 position_sticky col-lg-6 order-1 order-lg-3"
          >
            <div className="">
              <div className="d-flex align-items-center ps-3 mt-3 mt-lg-0 gap-2 ">
                <img src={cricket} alt="stumps" />
                <p className="mb-0 ff_inter fs_19 text-white fw-medium">
                  {sport}
                </p>
              </div>
            </div>
            <EventsInfo event={SelectedEvent} />
          </div>
          <div className="col-12 mt-4  col-lg-6  order-2 order-lg-1">
            {/* {loading && match?.markets?.length > 0 ? */}
            <div className="row overflow-auto matches-box-div">
              {match?.length !== 0 ? (
                match?.markets?.map((item, marketIndex) => {
                  return (
                    <div className="p-1">
                      <div
                        className="matches-div rounded-2  px-3 py-4 mt-3"
                        key={marketIndex}
                      >
                        <p className="text-white">{item.name}</p>
                        <div>
                          {item?.outcomes?.map((odds, outcomeIndex) => {
                            const rippleClass = rippleIndexes[
                              `${marketIndex}-${outcomeIndex}`
                            ]
                              ? "ripple"
                              : "";

                            return (
                              <div>
                                {betLock ? (
                                  <div className="position-relative">
                                    <div
                                      className={`bg_clr_dark_blue_2 cursor-pointer betting-info-div my-2 rounded-2 w-100 d-flex flex-column align-items-center justify-content-center text-white ${rippleClass}`}
                                      key={outcomeIndex}
                                      onClick={() => {
                                        if (odds.odds !== "NaN") {
                                          setBettingModal(true);
                                          dispatch(
                                            addSportsRadarBet({
                                              bhav: odds.odds,
                                              seletedItem: odds.name,
                                              team_name: match.name,
                                              tournament_name: "India Test",
                                              match_id: id,
                                              odd_id: odds.id,
                                              sports_type: sport,
                                              market_id: item.market_id,
                                            })
                                          );
                                        } else {
                                          toast.error(
                                            "You can bet on this odd"
                                          );
                                        }
                                      }}
                                    >
                                      <div className="d-flex text-center flex-column ">
                                        <p className="text-red mb-0">
                                          {getTwoWords(odds.name)}
                                        </p>
                                        <div className="">
                                          <p className="mb-0">
                                            {odds.odds == "NaN"
                                              ? "-"
                                              : odds.odds}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="d-flex justify-content-center w-100 position-absolute bg-suspend my-2 rounded-2 py-2">
                                      <p className="text-white">Bet Suspened</p>
                                    </div>
                                  </div>
                                ) : (
                                  <div
                                    className={`bg_clr_dark_blue_2 cursor-pointer betting-info-div my-2 rounded-2 w-100 d-flex flex-column align-items-center justify-content-center text-white ${rippleClass}`}
                                    key={outcomeIndex}
                                    onClick={() => {
                                      if (odds.odds !== "NaN") {
                                        setBettingModal(true);
                                        dispatch(
                                          addSportsRadarBet({
                                            bhav: odds.odds,
                                            seletedItem: odds.name,
                                            team_name: match.name,
                                            tournament_name: "India Test",
                                            match_id: id,
                                            odd_id: odds.id,
                                            sports_type: sport,
                                            market_id: item.market_id,
                                          })
                                        );
                                      } else {
                                        toast.error("You can bet on this odd");
                                      }
                                    }}
                                  >
                                    <div className="d-flex text-center flex-column ">
                                      <p className="text-red mb-0">
                                        {getTwoWords(odds.name)}
                                      </p>
                                      <div className="">
                                        <p className="mb-0">
                                          {odds.odds == "NaN" ? "-" : odds.odds}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="text-center text-white my-4">
                  <h4>No Match Odds Found</h4>
                </div>
              )}
            </div>
            {/* //     :
        //     <div className="d-flex w-100 justify-content-center align-items-center">
        //         <p className="text-white ff_inter fs_19 text-white fw-medium mt-4">No Odds Found</p>
        //     </div>
        // } */}
          </div>
        </div>
      </div>
      <div
        onClick={() => setBettingModal(false)}
        className={`body-overlay ${
          BettingModal === true ? "d-block" : "d-none"
        }`}
      ></div>
      <SportsRadarBettingModal
        active={BettingModal}
        setIsBettingFalse={() => {
          setBettingModal(false);
        }}
      />
    </div>
  );
}

export default OtherSportsEventDetails;
