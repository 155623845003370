import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { GetSportHistory } from "../Services/HistoryServices";
import { getLocalDate } from "../Services/Common";

const SportsHistory = () => {
  const [SportsHistory, setSportsHistory] = useState([]);
  const [selectedSport, setSelectedSport] = useState("Soccer"); // Default category set to "Soccer"
  const [filterDropDown, setFilterDropDown] = useState(false);

  // Fetch categories from redux store
  const Categoires = useSelector((state) => state.SportsRadar.allSports);

  useEffect(() => {
    console.log(Categoires);
  }, [Categoires]);

  const getSportBetHistory = () => {
    GetSportHistory(selectedSport).then((res) => {
      console.log(res);
      if (res?.bets) {
        setSportsHistory(res?.bets);
      } else {
        setSportsHistory([]);
      }
    });
  };

  // Fetch the bet history when the selected sport changes
  useEffect(() => {
    getSportBetHistory();
  }, [selectedSport]);

  return (
    <div className="container py-3">
      <h5 className="fs_16 fw-medium ff_heebo text-white">
        Sports Bet History
      </h5>

      <div className="d-flex justify-content-between align-items-center mt-3">
        <p className="text-white mb-0">Current Bets</p>
        <div className="position-relative">
          <Form.Select
            aria-label="Select Sport"
            className="bg_clr_dark_grey_3 text-white"
            value={selectedSport}
            onChange={(e) => setSelectedSport(e.target.value)}
          >
            {Categoires?.map((item, index) => (
              <option key={index} value={item.name}>
                {item.name}
              </option>
            ))}
          </Form.Select>
        </div>
      </div>

      {SportsHistory.length > 0 ? (
        <div className="my-4">
          {SportsHistory?.map((item, index) => (
            <div
              key={index}
              className={`bg_clr_dark_grey_3 p-3 rounded-1 mt-3 ${
                item.bet_type === "lay" ? "lay-border" : "back-border"
              }`}
            >
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <h5 className="mb-0 fs_16 ff_heebo fw-medium text-white text-uppercase">
                    Bet Amount: {item?.amount}
                  </h5>
                  <p className="mb-0 fs_11 ff_heebo mt-1 text-white">
                    {item.team_name}
                  </p>
                  <p className="mb-0 fs_11 ff_heebo mt-1 text-white">
                    {item.bet_title},{" "}
                    {item.sports_type !== "Cricket"
                      ? `Bhav ${item.bhav}`
                      : `Odd: ${item.odd}`}
                  </p>
                  <p className="mb-0 fs_11 ff_heebo mt-1 text-white">
                    {getLocalDate(item.createdAt)}
                  </p>
                </div>
                <div>
                  <div className="text-white">
                    Return amount:
                    <span className="fs_16 fw-medium ff_heebo clr_green">
                      {Math.floor(item?.return_amount)}
                    </span>
                  </div>
                  <p
                    className={`mb-0 fs_11 ff_heebo mt-1 ${
                      item?.bet_status === "won"
                        ? "text-success"
                        : item.bet_status === "loss"
                        ? "text-danger"
                        : "text-white"
                    } text-capitalize`}
                  >
                    Status: {item?.bet_status}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div>
          <h4 className="fs_16 fw-medium ff_heebo text-white text-center mt-4">
            No Bet History Found
          </h4>
        </div>
      )}
    </div>
  );
};

export default SportsHistory;
