import React, { useEffect, useState } from "react";
import { GetLoyaltyBouns } from "../../Services/Transactions";
import { getLocalDate, getLocalTime } from "../../Services/Common";

function LoyaltyBouns() {
  const [allLoyaltiyPoints, setAllLoyaltiyPoints] = useState([]);

  const GetallLoyalPoints = () => {
    GetLoyaltyBouns().then((res) => {
      console.log("loyalty res", res);
      setAllLoyaltiyPoints(res);
    });
  };

  useEffect(() => {
    GetallLoyalPoints();
  }, []);

  return (
    <div className="container py-4">
      <div>
        <h5 className="fs_16 fw-medium ff_heebo text-white">Loyalty Points</h5>
      </div>
      <div>
        {allLoyaltiyPoints.length > 0 ? (
          allLoyaltiyPoints.map((item, index) => {
            return (
              <div
                key={index}
                className="ps-3 pe-5 py-3 ff_heebo text-white bg_clr_dark_grey_3 rounded-1 border-0 w-100 mt-3 d-flex justify-content-between align-items-center"
              >
                <div className="d-flex flex-column">
                  <div className="d-flex align-items-center gap-2">
                    <span className="fs_16 fw-medium ff_heebo ">
                      {item?.level_name}
                    </span>
                    <span className="fs_12 fw-medium ff_heebo ">
                      ( ₹ {item.bonusAmount})
                    </span>
                  </div>
                  <span className="fs_10 fw-medium ff_heebo text-white">
                    {getLocalDate(item.createdAt) +
                      " " +
                      `(${getLocalTime(item.createdAt)})`}
                  </span>
                </div>
                <div
                  className={`fs_14 fw-medium ff_heebo text-capitalize ${
                    item?.bonus_clamed == true ? "clr_green" : "clr_yellow"
                  }`}
                >
                  {item?.bonus_clamed == true ? "Claimed" : "Claim Now"}
                </div>
              </div>
            );
          })
        ) : (
          <h4 className="fs_16 fw-medium ff_heebo text-white text-center mt-5">
            No Loyalty Points Found
          </h4>
        )}
      </div>
    </div>
  );
}

export default LoyaltyBouns;
