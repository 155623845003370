import React, { useEffect, useRef, useState } from "react";
import sportsIcon from "../../assets/images/png/sports.png";
import casinoIcon from "../../assets/images/png/casino.png";
import downArrow from "../../assets/images/svg/arrowDown.svg";
import { Col, Row } from "react-bootstrap";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getMe, SaveUserDetails } from "../../Services/AuthService";
import { setBalance, setUser } from "../../redux/User/UserSlice";
import { GetAllSports } from "../../redux/SportsRadar/SportsRadarSlice";
import { GetSideBarData } from "../../Services/SportsRadar";
import { GetAllSideBarData } from "../../redux/AllEvent/EventSlice";
function DesktopSidebar() {
  const [AllSportsCategory, setAllSportsCategory] = useState([]);
  const [showNav, setShowNav] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLogin, setIsLogin] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const User = useSelector((state) => state.User.User);
  const UserBalance = useSelector((state) => state.User.balance);
  const [showLogout, setShowLogout] = useState(false);
  const [openSearchModal, setopenSearchModal] = useState(false);
  const Cricket = useSelector((state) => state.Events.events);
  const sideBarData = useSelector((state) => state.Events.sideBar);
  const logoutRef = useRef(null);
  const [selectedSport, setSelectedSport] = useState(null);
  const [allLeagues, setAllLeagues] = useState([]);
  const [userIP, setUserIP] = useState("");
  const [userLocation, setUserLocation] = useState(null);
  const [events, setEvents] = useState([]);
  const getUserIP = async () => {
    try {
      const response = await axios.get("https://api.ipify.org/?format=json");
      console.log("IP DAta", response);
      setUserIP(response.data.ip);
      getUserLocation(response.data.ip);
    } catch (error) {
      console.error("Error fetching IP address:", error);
    }
  };

  const getUserLocation = async (ip) => {
    try {
      const response = await axios.get(`https://ipapi.co/${ip}/json/`);
      setUserLocation(response.data);
      console.log("country", response);
    } catch (error) {
      console.error("Error fetching user location:", error);
    }
  };

  useEffect(() => {
    console.log(User);
    if (User) {
      getUserIP();
    }
  }, [User]);

  const SaveUserIp = async () => {
    console.log("object");
    const data = {
      userId: User._id,
      ip: userIP,
    };
    SaveUserDetails(data);
  };

  useEffect(() => {
    if (userIP) {
      SaveUserIp();
    }
  }, [userIP]);

  const toggleLeague = (id) => {
    if (id == allLeagues) {
      setAllLeagues("");
    } else {
      setAllLeagues(id);
    }
  };

  const toggleSport = (sport) => {
    setSelectedSport((prevSport) => (prevSport === sport ? null : sport));
  };

  useEffect(() => {
    console.log("allLeagues", allLeagues);
  }, [allLeagues]);

  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const fetchEventsBySportId = async (sportId) => {
    const data = {
      date: await getCurrentDate(),
      sport_id: sportId,
    };

    // await GetSportById(data).then((res) => {
    //   const uniqueSeriesNames = [...new Set(res?.sport_events.map((event) => event.season.name))];
    //   setEvents(uniqueSeriesNames);
    // })
  };

  function Logout() {
    localStorage.clear();
    setShowNav(false);
    dispatch(setUser(null));
    window.location.reload();
  }

  const getUser = async () => {
    await getMe().then((res) => {
      if (res.status) {
        setCurrentUser(res.data);
        dispatch(setUser(res.data));
      } else {
        localStorage.clear("sfwa_tkn");
        window.location.reload();
      }
    });
  };

  useEffect(() => {
    const token = localStorage.getItem("sfwa_tkn");
    setIsLogin(!!token);
    if (token) {
      getUser();
    }
  }, []);

  useEffect(() => {
    if (selectedSport) {
      fetchEventsBySportId(selectedSport?.id);
    }
  }, [selectedSport]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (logoutRef.current && !logoutRef.current.contains(event.target)) {
        setShowLogout(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    dispatch(setBalance(currentUser?.balance));
  }, [currentUser]);

  useEffect(() => {}, [UserBalance]);

  function getGreeting() {
    const now = new Date();
    const hour = now.getHours();

    if (hour >= 5 && hour < 12) {
      return "Good Morning";
    } else if (hour >= 12 && hour < 18) {
      return "Good Afternoon";
    } else if (hour >= 18 && hour < 22) {
      return "Good Evening";
    } else {
      return "Good Night";
    }
  }
  useEffect(() => {
    dispatch(GetAllSports());
    dispatch(GetAllSideBarData());
  }, []);

  useEffect(() => {
    GetSideBarData().then((res) => {
      console.log(res);
      setAllSportsCategory(res);
    });
  }, []);
  return (
    <div className=" vh-100 overflow-y-auto ">
      <Row className="mt-2 ps-2">
        <Col className="col-6 pe-1 ">
          <img
            src={sportsIcon}
            className="w-100  rounded-3 cursor-pointer"
            onClick={() => {
              navigate("/sports");
            }}
          />
        </Col>
        <Col className="col-6 ps-1 ">
          <img
            src={casinoIcon}
            className="w-100  rounded-3 cursor-pointer"
            onClick={() => {
              navigate("/casino-page");
            }}
          />
        </Col>
      </Row>
      <h2
        style={{ color: "#22b264" }}
        className="mt-3 ff_inter clr_soil  fw-800 fs_22 ps-2"
      >
        All Sports
      </h2>
      <div className="mt-3 ps-2 ">
        <div className="">
          {sideBarData?.map((sport, index) => (
            <div key={index} className="">
              <div
                className="d-flex justify-content-between align-items-center gap-2 mt-2 pt-1 "
                onClick={() => toggleSport(sport)}
              >
                <p
                  className={`mb-0 ff_heebo fw-bold fs_14 cursor-pointer ${
                    selectedSport === sport ? "clr_green" : "text-white"
                  }`}
                >
                  {sport?.name + ` (${sport.tournament.length})`}
                </p>
                <div className="">
                  {selectedSport === sport ? (
                    <img
                      src={downArrow}
                      alt={sport.name}
                      className="image-rotate-up"
                    />
                  ) : (
                    <img
                      src={downArrow}
                      alt={sport.name}
                      className="image-rotate-down"
                    />
                  )}
                </div>
              </div>
              {selectedSport === sport && (
                <div className="mt-2 bg_clr_dark_grey_3">
                  <ul className="px-3 ps-0 pb-3 pt-2">
                    {sport.tournament.length > 0 ? (
                      sport?.tournament?.map((seriesEvents, index) => (
                        <li className="pt-2" key={index}>
                          <div
                            onClick={() => toggleLeague(seriesEvents?.name)}
                            className="d-flex ps-2 justify-content-between align-items-center pe-2"
                          >
                            <p
                              style={{
                                display: "inline-block",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap", // Ensure the text stays on one line
                                maxWidth: "160px", // Define the width to control the truncation
                                cursor: "pointer",
                                position: "relative",
                              }}
                              title={seriesEvents?.name + ` `}
                              onClick={() => toggleLeague(seriesEvents?.name)}
                              className={`ff_inter fw-medium fs_13 border-0 bg-transparent   d-flex align-items-center  text-start justify-content-between w-100 ${
                                allLeagues === seriesEvents?.name
                                  ? "clr_green"
                                  : "text-white"
                              }`}
                            >
                              {seriesEvents?.name + ` `}
                            </p>

                            {allLeagues === seriesEvents?.name ? (
                              <img
                                src={downArrow}
                                alt={sport.name}
                                className="image-rotate-up"
                              />
                            ) : (
                              <img
                                src={downArrow}
                                alt={sport.name}
                                className="image-rotate-down"
                              />
                            )}
                          </div>
                          <div
                            className={
                              allLeagues == seriesEvents?.name
                                ? "d-block ps-2"
                                : "d-none"
                            }
                          >
                            {seriesEvents.matche.map((event, index) => (
                              <p
                                style={{
                                  display: "inline-block",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap", // Ensure the text stays on one line
                                  maxWidth: "185px", // Define the width to control the truncation
                                  cursor: "pointer",
                                  position: "relative",
                                  color: "#cccc",
                                }}
                                title={event?.name}
                                key={index}
                                className="clr_grey_3 mb-1 ff_inter fs_14  cursor-pointer"
                                onClick={() => {
                                  if (sport.name == "Cricket") {
                                    navigate(
                                      "/sport_details/" +
                                        event?.id +
                                        "/" +
                                        sport?.name +
                                        "/" +
                                        event.match_start_time +
                                        "/" +
                                        event.betfair_event_id
                                    );
                                  } else {
                                    navigate(
                                      "/sport_details/" +
                                        event?.id +
                                        "/" +
                                        sport?.name
                                    );
                                  }
                                  setShowNav(false);
                                }}
                              >
                                {event?.name}
                              </p>
                            ))}
                          </div>
                        </li>
                      ))
                    ) : (
                      <span></span>
                    )}
                  </ul>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default DesktopSidebar;
