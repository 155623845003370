import React, { useEffect } from "react";
import AppNavbar from "../components/home/AppNavbar";
import speaker from "../assets/images/svg/speaker.svg";
import EventsInfo from "../components/betting/EventsInfo.";
import MatchOdds from "../components/betting/MatchOdds";
import BookMaker from "../components/betting/BookMaker";
import Fancy from "../components/betting/Fancy";
import CommenButton from "../components/CommenButton";
import Appfooter from "../components/footer/Appfooter";
import BottomNav from "../components/bottomnav/BottomNav";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const BettingMain = () => {
  const SelectedEvent = useSelector((state) => state.SelectedEvent.events);
  const navigate = useNavigate();

  useEffect(() => {
    if (SelectedEvent == null) {
      navigate(-1);
    }
  }, [SelectedEvent]);
  useEffect(() => {
    window.scroll("top", 0);
  }, []);
  return (
    <div>
      <div className="container px-3 mt-3">
        {/* <div className="bg_clr_dark_grey_3 p-2 rounded-2">
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <img style={{ width: "30px" }} src={speaker} alt="speaker" />
              <p className="mb-0 text-white ff_heebo fs_11">
                IPL Cup Winner Market Available On Our Exchange...
              </p>
            </div>
          </div>
        </div>
        <CommenButton /> */}
      </div>
      {SelectedEvent !== null && (
        <div>
          <EventsInfo event={SelectedEvent} />
          <MatchOdds event={SelectedEvent} />
          {SelectedEvent.market.bookmakerCount > 0 && (
            <BookMaker event={SelectedEvent} />
          )}
          {SelectedEvent.market.fancyCount > 0 && (
            <Fancy event={SelectedEvent} />
          )}
        </div>
      )}
      <Appfooter />
      <BottomNav />
    </div>
  );
};

export default BettingMain;
