import React, { useEffect, useRef, useState, useCallback } from "react";
import { Modal, Spinner } from "react-bootstrap";
import searchIcon from "../../assets/images/svg/searchIcon.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { GetDateDescription, TimePending } from "../../Services/Common";
import { useDispatch, useSelector } from "react-redux";
import debounce from "lodash.debounce"; // Debounce utility
import cross_icon from "../../assets/images/svg/cross_icon.svg";

function SearchModal({ open, close }) {
  const inputRef = useRef(null);
  const [value, setValue] = useState("");
  const [filterSportData, setFilterSportData] = useState([]);
  const sideBarData = useSelector((state) => state.Events.sideBar);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  // Filter function wrapped in useCallback to avoid re-creation on every render
  const filterSports = useCallback(
    (inputValue) => {
      if (!inputValue || !["/", "/sports", "/bet-history"].includes(pathname)) {
        setFilterSportData([]);
        return;
      }
      console.log(inputValue);
      const filtered = sideBarData
        .map((sport) => ({
          ...sport,
          tournament: sport.tournament
            .map((tournament) => ({
              ...tournament,
              matche: tournament.matche.filter((match) =>
                match.name.toLowerCase().includes(inputValue.toLowerCase())
              ),
            }))
            .filter((tournament) => tournament.matche.length > 0),
        }))
        .filter((sport) => sport.tournament.length > 0);
      console.log("filtered", filtered);
      setFilterSportData(filtered);
    },
    [sideBarData, pathname]
  );

  // Debounced version of filter function to prevent excessive filtering
  const debouncedFilter = useCallback(debounce(filterSports, 300), [
    filterSports,
  ]);

  useEffect(() => {
    debouncedFilter(value);
    // Cleanup debounce on unmount
    return () => debouncedFilter.cancel();
  }, [value, debouncedFilter]);

  useEffect(() => {
    if (open && inputRef.current) {
      inputRef.current.focus();
    }
  }, [open]);

  return (
    <Modal show={open} onHide={close} className="bg_modal">
      <Modal.Header className="border-bottom-0"></Modal.Header>
      <Modal.Body>
        <div className="px-3 py-5 mt-4 rounded-1 position-relative bg_clr_dark_blue_2">
          <div
            onClick={close}
            className="position-absolute end-0 top-0 cursor-pointer"
          >
            <img className="pe-2 pt-2" src={cross_icon} alt="cross_icon" />
          </div>
          <div className="search-input-div rounded-2 bg_clr_dark_grey_3 w-100 d-flex align-items-center px-3 mt-4">
            <input
              ref={inputRef}
              className="bg-transparent border-0 search-input ff_heebo fs_16 text-white opacity-26 w-100"
              type="text"
              placeholder="Search Your Game"
              onChange={(e) => setValue(e.target.value)}
              value={value}
            />
            <img style={{ width: "18px" }} src={searchIcon} alt="searchicon" />
          </div>

          {(pathname === "/" ||
            pathname === "/sports" ||
            pathname === "/bet-history") && (
            <div>
              {filterSportData.length > 0 ? (
                <div className="bg_clr_dark_grey_3 rounded p-4 mt-2">
                  <span className="text-white">Sport result</span>
                  <div className="border border-success my-3"></div>
                  <div>
                    {filterSportData.map((sport, sportIndex) =>
                      sport.tournament.map((tournament, tournamentIndex) =>
                        tournament.matche.map((match, matchIndex) => (
                          <div
                            key={`${sportIndex}-${tournamentIndex}-${matchIndex}`}
                            className="matches-di rounded-2 py-2 cursor-pointer"
                            onClick={() => {
                              if (sport.name == "Cricket") {
                                navigate(
                                  "/sport_details/" +
                                    match?.id +
                                    "/" +
                                    sport?.name +
                                    "/" +
                                    match.match_start_time +
                                    "/" +
                                    match.betfair_event_id
                                );
                              } else {
                                navigate(
                                  "/sport_details/" +
                                    match?.id +
                                    "/" +
                                    sport?.name
                                );
                              }
                              close();
                              setValue("");
                              setFilterSportData([]);
                            }}
                          >
                            <div>
                              <p className=" clr_dark_grey mb-0 ff_inter fw-medium fs_12">
                                {match?.name}
                              </p>
                            </div>
                            <div className="events-hr-line mt-3"></div>
                          </div>
                        ))
                      )
                    )}
                  </div>
                </div>
              ) : (
                <div className="bg_clr_dark_grey_3 rounded p-4 mt-2">
                  {value !== "" ? (
                    <div className="d-flex justify-content-center">
                      {filterSportData.length === 0 ? (
                        <span className="text-white">No data found</span>
                      ) : (
                        <Spinner size="sm" variant="success" />
                      )}
                    </div>
                  ) : (
                    <div>
                      <p
                        className="text-white text-center
                      "
                      >
                        No result show
                      </p>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default SearchModal;
