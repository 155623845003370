import React, { useState } from "react";
import vollyball from "../../assets/images/svg/vollyball.svg";
import leaf from "../../assets/images/svg/leaf.svg";
import home from "../../assets/images/svg/home.svg";
import bets from "../../assets/images/svg/bets.svg";
import profile from "../../assets/images/svg/profile.svg";
import { Link } from "react-router-dom";
import "./BottomNav.css";
import BetSlip from "./BetSlip";
import { useSelector } from "react-redux";

const BottomNav = () => {
  const user = useSelector((state) => state.User.User);

  return (
    <div className="py-3 bottom-nav bg_clr_dark_grey_3 w-100 bottom-nav-shadow">
      <div className="container w-100">
        <div className="d-flex align-items-center justify-content-between">
          <Link to={"/sports"}>
            <div style={{ width: "39px" }} className="text-center">
              <img src={vollyball} alt="vollyball" />
              <p className="ff_inter fw-medium fs_12 text-white mb-0 mt-1">
                Sports
              </p>
            </div>
          </Link>
          <Link to={"/casino-page"}>
            <div style={{ width: "39px" }} className="text-center">
              <img src={leaf} alt="leaf" />
              <p className="ff_inter fw-medium fs_12 text-white mb-0 mt-1">
                Casino
              </p>
            </div>
          </Link>
          <Link to={"/"}>
            <div className="home-bg d-flex align-items-center justify-content-center">
              <img className="home-img-shadow mb-1" src={home} alt="home" />
            </div>
          </Link>
          <Link to={user ? "/bet-history" : "/login/one-click-login"}>
            <div
              style={{ width: "39px" }}
              className="text-center position-relative"
            >
              <img src={bets} alt="bets" />
              <p className="ff_inter fw-medium fs_12 text-white mb-0 mt-1">
                Bets
              </p>
            </div>
          </Link>
          <Link to={user ? "/app/Profile" : "/login/one-click-login"}>
            <div style={{ width: "39px" }} className="text-center">
              <img src={profile} alt="profile" />
              <p className="ff_inter fw-medium fs_12 text-white mb-0 mt-1">
                Profile
              </p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BottomNav;
