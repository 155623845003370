import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from "react";
import speaker from "../../assets/images/svg/speaker.svg";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import CommenButton from "../CommenButton";
import EventsInfo from "../betting/EventsInfo.";
import {
  getFancy,
  getFancyOdds,
  SelectedSport,
} from "../../Services/SportsRadar";
import { Accordion, Modal } from "react-bootstrap";
import BetSlip from "../bottomnav/BetSlip";
import { addBet } from "../../redux/User/UserSlice";
import cross_icon from "../../assets/images/svg/cross_icon.svg";
import socketService from "../../Services/SocketService";
import { getBookMaker, getBookMakerby } from "../../Services/events";
import SportsRadarFancyCompenet from "./SportsRadarFancyCompenet";
import axios from "axios";
import DesktopScoreBoard from "../ScoreBoard/DesktopScoreBoard";

const EventDetails = () => {
  const SelectedEvent = useSelector((state) => state.SelectedEvent.events);
  const [betModal, setBetModal] = useState(false);
  const [allFancy, setAllFancy] = useState([]);
  const [MatchOdds, setMatchOdds] = useState([]);
  const dispatch = useDispatch();
  const { id, sport, date, eventId } = useParams();
  const [getBookMakerEvent, setBookMarkerEvnets] = useState(null);
  const [BookMaker, setBookMaker] = useState([]);
  const [allOddsMarkets, setAllOddsMarkets] = useState([]);
  const [allMatchOddsMarkets, setAllMatchOddsMarkets] = useState([]);
  const [event, setEvent] = useState("");
  const UserBalance = useSelector((state) => state.User.balance);
  const CurrentUser = useSelector((state) => state.User.User);
  const [lowbalance_alert, setLowBalanceAlert] = useState(false);
  const [selectedSeries, setSelectedSeries] = useState("");
  const [loginModal, setLoginModal] = useState(false);
  const [previousBackData, setPreviousBackData] = useState({});
  const [previousLayData, setPreviousLayData] = useState({});
  const [activeRipple, setActiveRipple] = useState({});
  const [match, setMatch] = useState();
  const [oddCall, setOddsCall] = useState(true);
  const rippleTimeouts = useRef({});

  useEffect(() => {
    const len = getBookMakerEvent?.length >= 2 ? 1 : 0;
    if (getBookMakerEvent != null) {
      getBookMakerby(getBookMakerEvent[len]?.market?.id).then((res) => {
        setBookMaker(res);
      });
    }
  }, [getBookMakerEvent]);

  const getAllFancy = useCallback(() => {
    const data = {
      event_id: eventId,
    };
    getFancy(data).then((res) => {
      const newMatchOdds =
        res?.catalogues?.filter(
          (item) =>
            item.marketName === "Match Odds" && item.status !== "INACTIVE"
        ) || [];
      const newFancy =
        res?.catalogues?.filter((item) => item.marketName !== "Match Odds") ||
        [];

      setMatchOdds((prevMatchOdds) => {
        if (JSON.stringify(prevMatchOdds) !== JSON.stringify(newMatchOdds)) {
          console.log("newMatchOdds", newMatchOdds);
          return newMatchOdds;
        }
        return prevMatchOdds;
      });

      setAllFancy((prevAllFancy) => {
        if (JSON.stringify(prevAllFancy) !== JSON.stringify(newFancy)) {
          return newFancy;
        }
        return prevAllFancy;
      });

      setEvent(res?.event);
    });
  }, [eventId]);

  const getSelectedgame = useCallback(async () => {
    console.log("first");
    const data = {
      sports_event_id: id,
      next_live_time: date,
    };
    await SelectedSport(data).then((res) => {
      console.log("res");
      if (res?.match_summary) {
        console.log(
          "kjkljk",
          res?.match_summary?.sport_event?.tournament?.$.name
        );
        setSelectedSeries(res?.match_summary?.sport_event?.tournament?.$.name);
      }
    });
  }, [id, date]);

  useEffect(() => {
    if (eventId) {
      console.log("kkk");
      getSelectedgame();
    }
  }, [id, getSelectedgame]);

  useEffect(() => {
    if (eventId) {
      getAllFancy();
    }
  }, [eventId, getAllFancy]);

  const market_ids = useMemo(() => {
    if (allFancy.length > 0) {
      return allFancy
        .filter((value) => value.status === "OPEN")
        .map((element) => element?.marketId)
        .filter((id) => id !== undefined)
        .join(",");
    }
    return null;
  }, [allFancy]);

  const market_oddsids = useMemo(() => {
    if (MatchOdds.length > 0) {
      return MatchOdds.filter((value) => value.status === "OPEN")
        .map((element) => element?.marketId)
        .filter((id) => id !== undefined)
        .join(",");
    }
    return null;
  }, [MatchOdds]);

  useEffect(() => {
    if (oddCall) {
      if (market_ids) {
        const data = {
          book_id: market_ids,
        };
        getFancyOdds(data).then((res) => {
          setAllOddsMarkets(res);
        });
      }
      if (market_oddsids) {
        const data = {
          book_id: market_oddsids,
        };
        getFancyOdds(data).then((res) => {
          setAllMatchOddsMarkets(res);
        });
      }
    }
  }, [market_ids, market_oddsids]);

  const triggerRippleEffect = useCallback((selectionId, type) => {
    setActiveRipple((prev) => ({
      ...prev,
      [`${selectionId}-${type}`]: true,
    }));

    clearTimeout(rippleTimeouts.current[`${selectionId}-${type}`]);
    rippleTimeouts.current[`${selectionId}-${type}`] = setTimeout(() => {
      setActiveRipple((prev) => ({
        ...prev,
        [`${selectionId}-${type}`]: false,
      }));
    }, 400);
  }, []);

  useEffect(() => {
    if (eventId) {
      socketService.connect();

      socketService.emit("join", { userId: eventId }, (error, message) => {
        if (error) {
          console.log(`Error joining: ${error}`);
        } else {
          console.log(message);
        }
      });

      const handleBookmark = (data) => {
        setOddsCall(false);
        setBookMaker(data.data);
      };

      const handleFancyOds = (data) => {
        console.log("match Facy ---->", data);
        setOddsCall(false);
        setAllOddsMarkets(data.data.data);
        setAllMatchOddsMarkets(data.data.data);
        const newMatchOdds =
          data?.data?.fancy_name?.filter(
            (item) => item.marketName === "Match Odds"
          ) || [];
        const newFancy =
          data?.data?.fancy_name?.filter(
            (item) => item.marketName !== "Match Odds"
          ) || [];
        setAllFancy(newFancy);
        setMatchOdds(newMatchOdds);
      };

      socketService.on("bookmark", handleBookmark);
      socketService.on("fancy_ods", handleFancyOds);
      socketService.on("sportsradar", (data) => {
        console.log("Hello", data);
      });
      return () => {
        socketService.off("bookmark", handleBookmark);
        socketService.off("fancy_ods", handleFancyOds);
        socketService.disconnect();
      };
    }
  }, [eventId, socketService]);

  useEffect(() => {
    console.log("MatchOdds", MatchOdds);
  }, [MatchOdds]);

  useEffect(() => {
    if (allMatchOddsMarkets && allFancy.length > 0) {
      allFancy.forEach((value) => {
        const oddsMarket = allOddsMarkets[value?.marketId];
        if (oddsMarket) {
          oddsMarket.runners.forEach((runner) => {
            if (runner.back && runner.back[0]) {
              const newBackData = runner.back[0].price || runner.back[0].line;
              const previousBack = previousBackData[runner.selectionId];
              if (newBackData !== previousBack) {
                triggerRippleEffect(runner.selectionId, "back");
              }
              setPreviousBackData((prev) => ({
                ...prev,
                [runner.selectionId]: newBackData,
              }));
            }

            if (runner.lay && runner.lay[0]) {
              const newLayData = runner.lay[0].price || runner.back[0].line;
              const previousLay = previousLayData[runner.selectionId];
              if (newLayData !== previousLay) {
                triggerRippleEffect(runner.selectionId, "lay");
              }
              setPreviousLayData((prev) => ({
                ...prev,
                [runner.selectionId]: newLayData,
              }));
            }
          });
        }
      });
    }
  }, [allOddsMarkets, allFancy, triggerRippleEffect]);

  function truncateToTwoWords(text) {
    const words = text.split(" ");
    if (words.length <= 1) {
      return text;
    }
    return words.slice(0, 1).join(" ") + "...";
  }

  const GetStaticData = async () => {
    const res = await axios({
      url: process.env.REACT_APP_BASEURL + "/getRedarSportsOdds",
      method: "POST",
      data: {
        match_id: id,
      },
    });
    console.log("res ====>", res.data);
    setMatch(res.data.data);
  };

  useEffect(() => {
    GetStaticData();
  }, []);

  return (
    <div className="">
      <div className="container px-3 mt-3">
        {/* <div className="bg_clr_dark_grey_3 p-2 rounded-2">
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <img style={{ width: "30px" }} src={speaker} alt="speaker" />
              <p className="mb-0 text-white ff_heebo fs_11">
                IPL Cup Winner Market Available On Our Exchange...
              </p>
            </div>
          </div>
        </div> */}
        {/* <CommenButton /> */}
      </div>
      <div className="container mb-5">
        <div className="row ">
          <div className="col-12 col-lg-6 order-lg-1 order-2">
            {MatchOdds?.length != 0 ? (
              <div className="container my-5 mt-3">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header className="d-flex align-items-center justify-content-between text-white ff_inter fw-medium fs_16">
                      Match_odds
                    </Accordion.Header>
                    <Accordion.Body>
                      {MatchOdds?.slice(0, 1).map((value, index) => {
                        const oddsMarket = allMatchOddsMarkets[value?.marketId];
                        return (
                          <div>
                            <div key={index} className="pb-1">
                              <div>
                                <div className="d-flex justify-content-between align-items-center">
                                  <div></div>
                                  <div className="d-flex gap-2">
                                    <p className="ff_heebo fs_12 mb-1 text-white text-nowrap">
                                      Min : {value?.marketCondition?.minBet}
                                    </p>
                                    <p className="ff_heebo fs_12 mb-1 text-white text-nowrap">
                                      Max : {value?.marketCondition?.maxBet}
                                    </p>
                                  </div>
                                </div>
                                {oddsMarket?.runners
                                  ?.slice(0, 2)
                                  ?.map((item, ind) => {
                                    return (
                                      <div key={ind}>
                                        <span className="text-white fs_12">
                                          {value?.runners[ind]?.name}
                                        </span>
                                        <div className="d-flex align-items-center justify-content-between">
                                          {item.status === "ACTIVE" ? (
                                            <div
                                              style={{ height: "32px" }}
                                              className="d-flex align-items-center w-100 gap-2 mt-2 mb-3"
                                            >
                                              {item?.back?.map((odd, inde) => (
                                                <div
                                                  key={inde}
                                                  className={`w-100 text-black text-center cursor-pointer blue-clr-div py-1 px-2 ${
                                                    activeRipple[
                                                      `${item.selectionId}-back`
                                                    ]
                                                      ? "ripple"
                                                      : ""
                                                  }`}
                                                  onClick={() => {
                                                    if (CurrentUser) {
                                                      if (
                                                        UserBalance <
                                                        value?.marketCondition
                                                          ?.minBet
                                                      ) {
                                                        setLowBalanceAlert(
                                                          true
                                                        );
                                                      } else {
                                                        setBetModal(true);
                                                        const data = {
                                                          Teams: event,
                                                          selectedBet:
                                                            odd.line == null
                                                              ? odd.price
                                                              : odd.line,
                                                          odd: value,
                                                          min: value
                                                            ?.marketCondition
                                                            ?.minBet,
                                                          max: value
                                                            ?.marketCondition
                                                            ?.maxBet,
                                                          type: "betfair",
                                                          betType: "back",
                                                          eventId:
                                                            value?.marketId,
                                                          slecetedId:
                                                            item?.selectionId,
                                                          apiType: "fancy",
                                                          odd_type:
                                                            value?.bettingType,
                                                          series:
                                                            selectedSeries,
                                                          seletedTeam:
                                                            value?.runners[ind]
                                                              ?.name,
                                                          show_profit:
                                                            value.bettingType ==
                                                            "ODDS"
                                                              ? true
                                                              : false,
                                                          type2: "bookmaker",
                                                          Bhav:
                                                            odd.line == null
                                                              ? odd.price
                                                              : odd.line,
                                                        };
                                                        dispatch(addBet(data));
                                                      }
                                                    } else {
                                                      setLoginModal(true);
                                                    }
                                                  }}
                                                >
                                                  <p className="mb-0 ff_heebo fw-bold fs_15">
                                                    {odd.line == null
                                                      ? odd.price
                                                      : odd.line || "-"}
                                                  </p>
                                                  <p className="mb-0 ff_heebo fs_9">
                                                    {odd.line == null
                                                      ? odd.size
                                                      : odd.price || "-"}
                                                  </p>
                                                </div>
                                              ))}

                                              {value?.runners[0]?.name !==
                                                "ODD" ||
                                              value?.runners[1]?.name !== "EVEN"
                                                ? item?.lay?.map(
                                                    (odd, inde) => (
                                                      <div
                                                        key={inde}
                                                        className={`w-100 text-black text-center cursor-pointer pink-clr-div py-1 px-2 ${
                                                          activeRipple[
                                                            `${item.selectionId}-lay`
                                                          ]
                                                            ? "ripple"
                                                            : ""
                                                        }`}
                                                        onClick={() => {
                                                          if (CurrentUser) {
                                                            if (
                                                              UserBalance <
                                                              value
                                                                ?.marketCondition
                                                                ?.minBet
                                                            ) {
                                                              setLowBalanceAlert(
                                                                true
                                                              );
                                                            } else {
                                                              setBetModal(true);
                                                              const data = {
                                                                Teams: event,
                                                                selectedBet:
                                                                  odd.line ==
                                                                  null
                                                                    ? odd.price
                                                                    : odd.line,
                                                                odd: value,
                                                                min: value
                                                                  ?.marketCondition
                                                                  ?.minBet,
                                                                max: value
                                                                  ?.marketCondition
                                                                  ?.maxBet,
                                                                type: "betfair",
                                                                betType: "lay",
                                                                eventId:
                                                                  value?.marketId,
                                                                slecetedId:
                                                                  item?.selectionId,
                                                                odd_type:
                                                                  value?.bettingType,
                                                                series:
                                                                  selectedSeries,
                                                                seletedTeam:
                                                                  value
                                                                    ?.runners[
                                                                    ind
                                                                  ]?.name,
                                                                type2:
                                                                  "bookmaker",
                                                                show_profit:
                                                                  value.bettingType ==
                                                                  "ODDS"
                                                                    ? true
                                                                    : false,
                                                                bhav:
                                                                  odd.line ==
                                                                  null
                                                                    ? odd.price
                                                                    : odd.line,
                                                              };
                                                              dispatch(
                                                                addBet(data)
                                                              );
                                                            }
                                                          } else {
                                                            setLoginModal(true);
                                                          }
                                                        }}
                                                      >
                                                        <p className="mb-0 ff_heebo fw-bold fs_15">
                                                          {odd.line == null
                                                            ? odd.price
                                                            : odd.line || "-"}
                                                        </p>
                                                        <p className="mb-0 ff_heebo fs_9">
                                                          {odd.line == null
                                                            ? odd.size
                                                            : odd.price || "-"}
                                                        </p>
                                                      </div>
                                                    )
                                                  )
                                                : ""}
                                            </div>
                                          ) : (
                                            <div className="position-relative w-100">
                                              <div className="w-100">
                                                <div
                                                  style={{ height: "32px" }}
                                                  className="d-flex align-items-center w-100 gap-2 mt-2 mb-3 w-100"
                                                >
                                                  <div className="text-black text-center blue-clr-div py-1 px-2 w-100">
                                                    <p className="mb-0 ff_heebo fw-bold fs_15">
                                                      {"-"}
                                                    </p>
                                                    <p className="mb-0 ff_heebo fs_9">
                                                      {"-"}
                                                    </p>
                                                  </div>

                                                  <div className="text-black text-center pink-clr-div py-1 px-2 w-100">
                                                    <p className="mb-0 ff_heebo fw-bold fs_15">
                                                      {"-"}
                                                    </p>
                                                    <p className="mb-0 ff_heebo fs_9">
                                                      {"-"}
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="position-absolute top-0 suspeded-overlay w-100 overlay-div d-flex align-items-center justify-content-center px-2 text-center">
                                                {item?.status}
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                            <div className="match-odds-hr-line my-3"></div>
                          </div>
                        );
                      })}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            ) : (
              <div className="text-center text-white my-4">
                <h4>No Match Odds Found</h4>
              </div>
            )}
            {allFancy.length != 0 && (
              <SportsRadarFancyCompenet
                activeRipple={activeRipple}
                allFancy={allFancy}
                allOddsMarkets={allOddsMarkets}
                setBetModal={setBetModal}
                setLoginModal={setLoginModal}
                setLowBalanceAlert={setLowBalanceAlert}
                event={event}
                selectedSeries={selectedSeries}
              />
            )}
          </div>
          <div
            className="col-12 position_sticky col-lg-6 order-lg-2 order-1 mt-0 "
            style={{ top: "73px" }}
          >
            <EventsInfo event={SelectedEvent} />
          </div>
        </div>
      </div>

      {/* Modals for low balance and login */}
      <Modal
        show={lowbalance_alert}
        onHide={() => setLowBalanceAlert(false)}
        centered
      >
        <Modal.Body>
          <div className="bg_clr_green px-3 py-5 mt-4 rounded-1 position-relative">
            <img
              className=" position-absolute cursor-pointer top-0 end-0 mt-3 me-3"
              onClick={() => setLowBalanceAlert(false)}
              src={cross_icon}
              alt="close"
            />
            <h3 className="text-center py-4 fs-2 text-white">
              Your Current Balance is low for place bet
            </h3>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={loginModal} onHide={() => setLoginModal(false)} centered>
        <Modal.Body>
          <div className="bg_clr_green px-3 py-5 mt-4 rounded-1 position-relative">
            <img
              className=" position-absolute top-0 end-0 me-3 mt-3"
              onClick={() => setLoginModal(false)}
              src={cross_icon}
              alt="close"
            />
            <h3 className="text-center mb-0 ff_heebo text-white">
              Please Login first to<br></br> place bet
            </h3>
            <div className="d-flex justify-content-center">
              <button
                onClick={() => setLoginModal(false)}
                className=" px-4 py-2 rounded-2 border-0 ff_heebo fw-semibold mt-4"
              >
                Login
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <div
        onClick={() => setBetModal(false)}
        className={`body-overlay ${betModal === true ? "d-block" : "d-none"}`}
      ></div>
      <BetSlip
        active={betModal}
        setIsBettingFalse={() => setBetModal(false)}
        matchEvents={allFancy}
        matchOEvents_ids={allOddsMarkets}
        matchOdds={MatchOdds}
        matachOdds_ids={allMatchOddsMarkets}
      />
    </div>
  );
};

export default EventDetails;
