import { RouterProvider } from "react-router-dom";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import router from "./Routes/routes";
import { useEffect, useState } from "react";
import socketService from "./Services/SocketService";
import { useDispatch, useSelector } from "react-redux";
import { SaveUser } from "./Services/AuthService";
import { Modal } from "react-bootstrap";
import { setBalance } from "./redux/User/UserSlice";
import DesktopRoutes from "./Routes/DesktopRoutes";

function App() {
  const User = useSelector((state) => state.User.User);
  const [show, setShow] = useState(false);
  const [logoutTimeout, setLogoutTimeout] = useState(3000); // New state variable for timeout duration
  const [deviceWidth, setDeviceWidth] = useState(window.innerWidth); // Track device width
  const dispatch = useDispatch();

  // Function to get machine ID
  async function getMachineId() {
    let machineId = await localStorage.getItem("MachineId");
    if (!machineId) {
      machineId = await crypto.randomUUID();
      await localStorage.setItem("MachineId", machineId);
    }
    return machineId;
  }

  // Update device width on window resize
  useEffect(() => {
    const handleResize = () => {
      setDeviceWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Update user balance
  const SetUserBalance = (balance) => {
    dispatch(setBalance(balance));
  };

  useEffect(() => {
    getMachineId();
    if (User) {
      let machineId = localStorage.getItem("MachineId");
      socketService.connect();
      socketService.on("someEvent", handleSomeEvent);
      console.log(User?._id);
      socketService.emit("join", { userId: User._id }, (error, message) => {
        if (error) {
          console.log(`Error joining: ${error}`);
        } else {
          console.log(message);
        }
      });
      socketService.emit("join", { userId: machineId }, (error, message) => {
        if (error) {
          console.log(`Error joining: ${error}`);
        } else {
          console.log(message);
        }
      });
      socketService.on("balance", (data) => {
        console.log("balance", data);
        SetUserBalance(data?.balance);
      });
      socketService.on("logout_request", () => {
        setShow(true);
        setTimeout(() => {
          localStorage.clear();
          setShow(false);
          window.location.reload();
        }, logoutTimeout);
      });
    }
  }, [socketService, User, logoutTimeout]);

  const handleSomeEvent = (data) => {
    console.log("Received someEvent: ", data);
  };

  useEffect(() => {
    if (User) {
      getMachineId();
      let machineId = localStorage.getItem("MachineId");
      const data = {
        userId: User?._id,
        device_id: machineId,
      };
      SaveUser(data).then((res) => {
        console.log(res);
      });
    }
  }, [User]);

  // Conditional UI for mobile and desktop
  const renderMobileUI = () => (
    <div className="mobile-view">
      <h1>Mobile View</h1>
      {/* Add more UI elements specific to mobile */}
      <p>Your device width is {deviceWidth}px</p>
    </div>
  );

  const renderDesktopUI = () => (
    <div className="desktop-view">
      <h1>Desktop View</h1>
      {/* Add more UI elements specific to desktop */}
      <p>Your device width is {deviceWidth}px</p>
    </div>
  );

  return (
    <div className="pb-5 pb-lg-0 mb-lg-0 mb-4">
      {/* <RouterProvider router={router} /> */}
      {/* Render different UIs based on device width */}
      {deviceWidth < 1024 ? (
        <RouterProvider router={router} />
      ) : (
        <RouterProvider router={DesktopRoutes} />
      )}
      <Modal className="bg_modal" show={show} backdrop="static" centered>
        <Modal.Body className="text-center text-white">
          <div className="bg_clr_green px-3 py-5 mt-4 rounded-1 position-relative">
            <h3 className="fs_14 fw-medium ff_heebo text-white mt-3 mb-0">
              Login detected to other devices. Your ID will be logged out in{" "}
              {logoutTimeout / 1000} seconds.
            </h3>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default App;
