import React, { useState } from "react";
import { Form, Modal, Toast } from "react-bootstrap";
import {
  loginWithPasword,
  oneClickLoginCreate,
} from "../../Services/AuthService";
import cross_icon from "../../assets/images/svg/cross_icon.svg";
import copy from "../../assets/images/png/copy.png";
import { ToastContainer, toast } from "react-toastify";
import { countryList } from "../../Services/Common";

const OneClickLogin = () => {
  const [country, setCountry] = useState("");
  const [currency, setCurrency] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [agreed, setAgreed] = useState(false);
  const [errors, setErrors] = useState({});
  const [LoginModal, setLoginModal] = useState(false);
  const [detailsModal, setDetailsModal] = useState(false);
  const [details, setDetails] = useState("");

  const handleOneclick = async () => {
    const validationErrors = {};
    // if (!country) validationErrors.country = "Country is required";
    // if (!currency) validationErrors.currency = "Currency is required";
    // if (!agreed) validationErrors.agreed = "You must agree to the terms";

    // setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      const data = {
        country: country,
        currency: currency,
      };
      await oneClickLoginCreate(data).then((res) => {
        if (res.status) {
          setDetails(res);
          setDetailsModal(true);
        }
      });
    }
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        toast.success("Copied to clipboard successfully!");
        console.log("Copied to clipboard successfully!");
      },
      (err) => {
        toast.error("Failed to copy");
        console.error("Failed to copy text: ", err);
      }
    );
  };

  const hanldeLoginWithEmail = async () => {
    if (username && password) {
      const data = {
        username: username,
        password: password,
      };
      loginWithPasword(data).then((Res) => {
        console.log(Res);
        if (Res.status) {
          localStorage.setItem("sfwa_tkn", Res.token);
          window.location.reload();
        } else {
          toast.error("invalid credentials");
        }
      });
    } else {
      toast.error("Fill all inputs");
    }
  };

  const hanldeGoTohome = () => {
    localStorage.setItem("sfwa_tkn", details.token);
    window.location.reload();
  };

  return (
    <div>
      <div style={{ maxWidth: "600px" }} className="mx-auto">
        <ToastContainer hideProgressBar={true} bodyClassName={"w-50"} />
        <div className="d-flex justify-content-between flex-column">
          <div>
            {/* <div className="pt-4 w-100 d-flex flex-column">
              <label className="clr_grey ff_roboto" htmlFor="">
                Choose Country <span className="clr_red">*</span>
              </label>
              <Form.Select
                size="md"
                className=" ff_roboto clr_grey border-2 mt-3 p-3"
                onChange={(e) => {
                  setCountry(e.target.value);
                }}
                value={country}
              >
                <option value="">Select a country</option>
                {countryList.map((item, index) => {
                  return <option value={item.code}>{item.name}</option>;
                })}
              </Form.Select>
              {errors.country && (
                <p className="text-danger">{errors.country}</p>
              )}
            </div>
            <div className="pt-4 w-100 d-flex flex-column">
              <label className="clr_grey ff_heebo" htmlFor="">
                Choose Currency <span className="clr_red">*</span>
              </label>
              <Form.Select
                size="md"
                className=" ff_heebo clr_grey border-2 mt-3 p-3"
                onChange={(e) => {
                  setCurrency(e.target.value);
                }}
                value={currency}
              >
                <option value="">Select a currency</option>
                <option value="BRL">R$-Brazilian real (BRL)</option>
              </Form.Select>
              {errors.currency && (
                <p className="text-danger">{errors.currency}</p>
              )}
            </div> */}
          </div>
          <div className="mt-3">
            {/* <div className="d-flex gap-3 align-items-start">
              <input
                className=""
                id="check"
                type="checkbox"
                onChange={(e) => setAgreed(e.target.checked)}
                checked={agreed}
              />
              <label className="fs_12 ff_roboto text-white" htmlFor="check">
                I agree with User Agreements, Terms & Conditions and confirm
                that I am over 18 years old
              </label>
            </div> */}
            {/* {errors.agreed && <p className="text-danger">{errors.agreed}</p>} */}
            <button
              className="w-100 ff_roboto mt-4 fs_16 text-white bg_clr_green border-0 p-3 rounded-1"
              onClick={handleOneclick}
            >
              Demo Login
            </button>
            <button
              className="w-100 mt-4 fw-bold ff_roboto fs_16 text-white border-0 bg_clr_dark_grey_3 p-3 rounded-1"
              onClick={() => {
                setLoginModal(true);
              }}
            >
              <span className="fw-normal clr_green">Log in with</span> Password
            </button>
          </div>
        </div>
      </div>

      <Modal
        className="bg_modal"
        show={LoginModal}
        onHide={() => {
          setLoginModal(false);
        }}
        centered
      >
        <Modal.Body className="text-center text-white">
          <div className="bg_clr_dark_grey_2 px-3 py-5 mt-4 rounded-1 position-relative">
            <div
              onClick={() => {
                setLoginModal(false);
              }}
              className="position-absolute end-0 top-0"
            >
              <img className="pe-2 pt-2" src={cross_icon} alt="cross_icon" />
            </div>
            <div className="w-100 text-start">
              <div>
                <label>Username</label>
                <div className="search-input-div rounded-2 bg_clr_dark_grey_3 w-100 d-flex align-items-center px-3 mt-2">
                  <input
                    className="bg-transparent border-0 search-input ff_heebo fs_16 text-white opacity- w-100"
                    type="text"
                    placeholder="Enter your username"
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div>
              </div>
              <div className="mt-3">
                <label>Password</label>
                <div className="search-input-div rounded-2 bg_clr_dark_grey_3 w-100 d-flex align-items-center px-3 mt-2">
                  <input
                    className="bg-transparent border-0 search-input ff_heebo fs_16 text-white opacity- w-100"
                    type="password"
                    placeholder="**********"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </div>
              <button
                className="w-100 ff_roboto mt-4 fs_16 text-white bg_clr_green border-0 p-3 rounded-1"
                onClick={hanldeLoginWithEmail}
              >
                Continue
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* Details Modal */}
      <Modal
        className="bg_modal"
        show={detailsModal}
        onHide={() => {
          setDetailsModal(false);
        }}
        centered
        backdrop="static"
      >
        <Modal.Body className="text-center text-white">
          <div className="bg_clr_green px-3 py-5 mt-4 rounded-1 position-relative">
            <div
              onClick={() => {
                setDetailsModal(false);
              }}
              className="position-absolute end-0 top-0"
            >
              <img className="pe-2 pt-2" src={cross_icon} alt="cross_icon" />
            </div>
            <div>
              <div>
                <h1 className="fs_24 ff_roboto text-white">
                  Thanks For the <br /> Registration!
                </h1>
              </div>
              <div className="bg_clr_dark_grey_3 p-3 rounded">
                <div className="d-flex justify-content-between w-100 align-items-center text-start">
                  <div>
                    <div>
                      <span>username: {details?.data?.username}</span>
                    </div>
                    <div>
                      <span>password: {details?.data?.password}</span>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between mt-3 align-items-center">
                  <div
                    onClick={() =>
                      handleCopy(
                        `username: ${details?.data?.username}\npassword: ${details?.data?.password}`
                      )
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <img src={copy} height={20} width={20} className="me-1" />
                    Copy
                  </div>
                  <div>
                    <button
                      className="w-100 ff_roboto fs_16 text-white bg_clr_green border-0 py-1 px-3 rounded-1"
                      onClick={hanldeGoTohome}
                    >
                      Go to Home
                    </button>
                  </div>
                </div>
              </div>
              <div></div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default OneClickLogin;
