import React, { useEffect } from "react";
import bonus from "../../assets/images/svg/bonus.svg";
import accountStatement from "../../assets/images/svg/accountStatement.svg";
import currentBetsAndHistory from "../../assets/images/svg/currentBetsAndHistory.svg";
import activityLog from "../../assets/images/svg/activityLog.svg";
import casinoResults from "../../assets/images/svg/casinoResults.svg";
import liveCasinoBets from "../../assets/images/svg/liveCasinoBets.svg";
import sportBooksBets from "../../assets/images/svg/sportBooksBets.svg";
import supportTicket from "../../assets/images/svg/supportTicket.svg";
import lock from "../../assets/images/svg/lock.svg";
import securityAuth from "../../assets/images/svg/securityAuth.svg";
import verification from "../../assets/images/svg/verification.svg";
import logout from "../../assets/images/svg/logout.svg";
import { Link, useNavigate } from "react-router-dom";
import BottomNav from "./BottomNav";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../redux/User/UserSlice";
import { ProgressBar } from "react-bootstrap";
import { Stepper } from "react-form-stepper";

const Profile = () => {
  const User = useSelector((state) => state.User.User);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  function Logout() {
    localStorage.clear();
    dispatch(setUser(null));
    window.location.reload();
  }

  useEffect(() => {
    console.log("User=====>", User);
  }, [User]);
  return (
    <div className="pt-3 pb-4">
      <div className="container px-3">
        <h2 className="clr_soil mb-0 ff_inter fw-800 fs_22">My Profile</h2>
        <div className="bg_clr_dark_grey_3 p-3 rounded my-3">
          <div className="d-flex flex-column align-items-center gap-2">
            <div>
              <img
                src="https://easy-peasy.ai/cdn-cgi/image/quality=80,format=auto,width=700/https://fdczvxmwwjwpwbeeqcth.supabase.co/storage/v1/object/public/images/3077b9f6-85d4-4e22-8f27-750c0afa0251/d659562d-ffaa-4317-bf7e-d07333a4bd6d.png"
                width={50}
                className="rounded-circle"
              />
            </div>
            <div className="text-white fs_14">
              <span>{User?.fullName}</span>
              <span className="mb-0 ps-2">{User?.lastName}</span>
            </div>
          </div>
          <div>
            {/* <p className="mb-0 clr_soil mt-3 text-uppercase">
              Silver
            </p> */}
            <div className="w-100 overflow-x-auto">
              <Stepper
                steps={[
                  { label: "Silver" },
                  { label: "Gold" },
                  { label: "Platinum" },
                  { label: "Diamond" },
                  { label: "Elite" },
                ]}
                activeStep={1}
                className="text-white gap-3"
              />
            </div>
            <ProgressBar
              variant="success"
              now={45}
              className="range w-100 mt-3"
            />
            <p className="text-white mt-2 mb-0">
              Complete 20K wagering by 01/Nov/2022 to reach Platinium .
            </p>
          </div>
          <div>
            <button className="btn btn-success mt-4 w-100">
              See Your Benefits
            </button>
          </div>
        </div>
        <div className="mt-4">
          <Link
            to={"/bouns"}
            className="ff_heebo fw-medium fs_16 mb-0 text-white ms-1"
          >
            <div className="d-flex align-items-center gap-2">
              <img src={bonus} alt="bonus" />
              Bonus
            </div>{" "}
          </Link>
          <div className="profile-sec-hr-line my-3"></div>
          <Link to={"/app/transactions"}>
            <div className="d-flex align-items-center gap-2">
              <img src={accountStatement} alt="bonus" />
              <p className="ff_heebo fw-medium fs_16 mb-0 text-white ms-1">
                Account Statement
              </p>
            </div>
          </Link>
          <div className="profile-sec-hr-line my-3"></div>
          <Link to={"/bet-history"}>
            <div className="d-flex align-items-center gap-2">
              <img src={currentBetsAndHistory} alt="bonus" />
              <p className="ff_heebo fw-medium fs_16 mb-0 text-white ms-1">
                Current Bets & History
              </p>
            </div>
          </Link>
          <div className="profile-sec-hr-line my-3"></div>
          <div className="d-flex align-items-center gap-2">
            <img src={activityLog} alt="bonus" />
            <p className="ff_heebo fw-medium fs_16 mb-0 text-white ms-1">
              Activity Log
            </p>
          </div>
          <div className="profile-sec-hr-line my-3"></div>
          <div
            className="d-flex align-items-center gap-2"
            onClick={() => {
              navigate("/app/loyalty-point");
            }}
          >
            <img src={casinoResults} alt="bonus" />
            <p className="ff_heebo fw-medium fs_16 mb-0 text-white ms-1">
              Loyalty Points
            </p>
          </div>
          <div className="profile-sec-hr-line my-3"></div>
          <Link to={"/betHistory"}>
            <div className="d-flex align-items-center gap-2">
              <img src={liveCasinoBets} alt="bonus" />
              <p className="ff_heebo fw-medium fs_16 mb-0 text-white ms-1">
                Live Casino Bets
              </p>
            </div>
          </Link>
          <div className="profile-sec-hr-line my-3"></div>
          <Link to={"/sportsHistory"}>
            <div className="d-flex align-items-center gap-2">
              <img src={sportBooksBets} alt="bonus" />
              <p className="ff_heebo fw-medium fs_16 mb-0 text-white ms-1">
                SportBook Bets
              </p>
            </div>
          </Link>
          {/* <div className="profile-sec-hr-line my-3"></div>
                <div className="d-flex align-items-center gap-2">
                  <img src={supportTicket} alt="bonus" />
                  <p className="ff_heebo fw-medium fs_16 mb-0 text-white ms-1">
                    Support Ticket
                  </p>
                </div>
                <div className="profile-sec-hr-line my-3"></div>
                <div className="d-flex align-items-center gap-2">
                  <img src={lock} alt="bonus" />
                  <p className="ff_heebo fw-medium fs_16 mb-0 text-white ms-1">
                    Change Password
                  </p>
                </div> */}
          <div className="profile-sec-hr-line my-3"></div>
          {/* <div className="d-flex align-items-center gap-2">
                  <img src={securityAuth} alt="bonus" />
                  <p className="ff_heebo fw-medium fs_16 mb-0 text-white ms-1">
                    Security Auth
                  </p>
                </div>
                <div className="profile-sec-hr-line my-3"></div>
                <div className="d-flex align-items-center gap-2">
                  <img src={verification} alt="bonus" />
                  <p className="ff_heebo fw-medium fs_16 mb-0 text-white ms-1">
                    Verification
                  </p>
                </div>
                <div className="profile-sec-hr-line my-3"></div> */}
          <div
            className="d-flex cursor-pointer align-items-center gap-2"
            onClick={Logout}
          >
            <img src={logout} alt="bonus" />
            <p className="ff_heebo fw-medium fs_16 mb-0 text-white ms-1">
              Logout
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
