import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import on_click from "../../assets/images/svg/one-click-icon.svg";
import moble_icon from "../../assets/images/svg/phone_icon.svg";
import social_icon from "../../assets/images/svg/social_icon.svg";
import mail_icon from "../../assets/images/svg/mail_icon.svg";

const LoginHero = () => {
  const [activeButton, setActiveButton] = useState(() => {
    return localStorage.getItem("activeButton");
  });
  useEffect(() => {
    localStorage.setItem("activeButton", activeButton);
  }, [activeButton]);

  const handleClick = (buttonName) => {
    setActiveButton(buttonName);
  };

  const { pathname } = useLocation();

  return (
    <div>
      <div style={{ maxWidth: "600px" }} className="mx-auto">
        <Link to={"/"}>
          <h1 className="text-center fst-italic mb-0 clr_green fs_48 fw-bolder">
            45<span className=" text-white">X</span>
          </h1>
        </Link>
        <p className=" text-center text-white">Play & Win</p>
        <div className="row justify-content-center align--center mt-4">
          <div className="col-md-2 px-1 col-3 ">
            <Link to="/login/one-click-login" className="h-100">
              <div
                onClick={() => handleClick("one-click")}
                className={`text-center rounded-1 w-100 h-100 p-2 ${
                  pathname === "/login/one-click-login"
                    ? "bg_clr_green"
                    : "bg_clr_dark_grey_3"
                }`}
              >
                <img width={20} src={on_click} alt="on_click" />
                <p className="fs_11 text-white mb-0 pt-2">one click</p>
              </div>
            </Link>
          </div>
          <div className="col-md-2 px-1 col-3">
            <Link to="/login/phone-login">
              <div
                onClick={() => handleClick("phone-login")}
                className={`text-center rounded-1 w-100 h-100 p-2 ${
                  pathname === "/login/phone-login"
                    ? "bg_clr_green"
                    : "bg_clr_dark_grey_3"
                }`}
              >
                <img src={moble_icon} alt="on_click" />
                <p className="fs_11 text-white pt-2 mb-0">Phone Login</p>{" "}
              </div>
            </Link>
          </div>

          <div className="col-md-2 px-1 col-3">
            <Link to="/login/social-login">
              <div
                onClick={() => handleClick("social-login")}
                className={`text-center rounded-1 w-100 h-100 p-2 ${
                  pathname === "/login/social-login"
                    ? "bg_clr_green"
                    : "bg_clr_dark_grey_3"
                }`}
              >
                <img src={social_icon} alt="on_click" />
                <p className="fs_11 text-white pt-2 mb-0">Social Login</p>{" "}
              </div>
            </Link>
          </div>

          <div className="col-md-2 px-1 col-3">
            <Link to="/login/mail-login" className="h-100">
              <div
                onClick={() => handleClick("mail-login")}
                className={`text-center rounded-1 w-100 h-100 p-2 ${
                  pathname === "/login/mail-login"
                    ? "bg_clr_green"
                    : "bg_clr_dark_grey_3"
                }`}
              >
                <img src={mail_icon} alt="on_click" />
                <p className="fs_11 text-white pt-2 mb-0">Mail Login</p>{" "}
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginHero;
