import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Offcanvas, Row } from "react-bootstrap";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { getMe } from "../../Services/AuthService";
import { setUser } from "../../redux/User/UserSlice";
import searchIcon from "../../assets/images/svg/searchIcon.svg";
import { GetAllSports } from "../../redux/SportsRadar/SportsRadarSlice";
import DesktopSidebar from "./DesktopSidebar";
import SearchModal from "../Modals/SearchModal";
import UserLogo from "../../assets/images/png/HomeUserLogo.png";
import speaker from "../../assets/images/svg/speaker.svg";
import bonus from "../../assets/images/svg/bonus.svg";
import accountStatement from "../../assets/images/svg/accountStatement.svg";
import currentBetsAndHistory from "../../assets/images/svg/currentBetsAndHistory.svg";
import activityLog from "../../assets/images/svg/activityLog.svg";
import casinoResults from "../../assets/images/svg/casinoResults.svg";
import liveCasinoBets from "../../assets/images/svg/liveCasinoBets.svg";
import sportBooksBets from "../../assets/images/svg/sportBooksBets.svg";
import supportTicket from "../../assets/images/svg/supportTicket.svg";
import lock from "../../assets/images/svg/lock.svg";
import securityAuth from "../../assets/images/svg/securityAuth.svg";
import verification from "../../assets/images/svg/verification.svg";
import logout from "../../assets/images/svg/logout.svg";
// import BottomNav from "./BottomNav";
import { useDispatch, useSelector } from "react-redux";
// import { setUser } from "../../redux/User/UserSlice";
import { ProgressBar } from "react-bootstrap";
import { Stepper } from "react-form-stepper";
import "../Layout/layout.css";
import on_click from "../../assets/images/svg/one-click-icon.svg";
import moble_icon from "../../assets/images/svg/phone_icon.svg";
import social_icon from "../../assets/images/svg/social_icon.svg";
import mail_icon from "../../assets/images/svg/mail_icon.svg";
import OneClickLogin from "../auth/OneClickLogin";
import PhoneLogin from "../auth/PhoneLogin";
import SocialLogin from "../auth/SocialLogin";
import MailLogin from "../auth/MailLogin";

function DestopMainLayout() {
  const User = useSelector((state) => state.User.User);
  const UserBalance = useSelector((state) => state.User.balance);
  const [currentUser, setCurrentUser] = useState(null);
  const [openSearchModal, setopenSearchModal] = useState(false);
  const [showCanvas, setShowCanvas] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [showRegisterModal, setShowRegisterModal] = useState(false);

  const handleRegisterModal = () => setShowRegisterModal(!showRegisterModal);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getUser = async () => {
    await getMe().then((res) => {
      if (res.status) {
        setCurrentUser(res.data);
        dispatch(setUser(res.data));
      } else {
        localStorage.clear("sfwa_tkn");
        window.location.reload();
      }
    });
  };
  useEffect(() => {
    const token = localStorage.getItem("sfwa_tkn");
    setIsLogin(!!token);

    if (token) {
      getUser();
    }
  }, []);
  useEffect(() => {
    dispatch(GetAllSports());
  }, []);

  // const User = useSelector((state) => state.User.User);
  function Logout() {
    localStorage.clear();
    dispatch(setUser(null));
    window.location.reload();
  }

  useEffect(() => {
    console.log("User=====>", User);
  }, [User]);

  const [activeButton, setActiveButton] = useState("one-click");

  const handleClick = (buttonName) => {
    setActiveButton(buttonName);
  };

  const renderComponent = () => {
    switch (activeButton) {
      case "one-click":
        return <OneClickLogin />;
      case "phone-login":
        return <PhoneLogin />;
      case "social-login":
        return <SocialLogin />;
      case "mail-login":
        return <MailLogin />;
      default:
        return null; // No component to render if none is selected
    }
  };

  const { pathname } = useLocation();
  return (
    <div className="">
      <Row className="m-0 ">
        <Col lg={2} className="bg-sidebar position-fixed vh-100">
          <DesktopSidebar />
        </Col>
        <Col lg={12} className="">
          <div
            style={{ zIndex: "4" }}
            className=" col-10  end-0 p-2 ms-2 ps-1 bg_clr_dark_grey_3 position-fixed top-0"
          >
            <div className="d-flex align-items-center justify-content-between w-100 gap-1">
              <div className="d-flex gap-4 w-100 align-items-center">
                <div>
                  <Link to="/">
                    <h2 className="mb-0 cursor-pointer clr_green ff_inter fst-italic fw-900 fs_28">
                      45<span className="text_x">X</span>
                    </h2>
                  </Link>
                </div>
                <div className="d-flex rounded-2 bg_clr_dark_grey_2 p-2 align-items-center">
                  <marquee className="w-100">
                    <p className="mb-0 text-white ff_heebo fs_11">
                      Experience the Thrill of Winning – Join 45X Today and
                      start playing
                    </p>
                  </marquee>
                  <img
                    style={{
                      width: "30px",
                      animation: "rotateIcon 2s linear infinite", // Add animation here
                    }}
                    src={speaker}
                    alt="speaker"
                  />
                </div>
              </div>{" "}
              <div className="">
                <div className="pe-2 ">
                  <div
                    style={{ border: "2px solid transparent" }}
                    className=" rounded-2 bg_clr_dark_grey_2 d-flex cursor-pointer align-items-center  pe-3 ps-3"
                    onClick={() => {
                      setopenSearchModal(true);
                    }}
                  >
                    {/* <div
                        className="bg-transparent border-0 search-input ff_heebo text-nowrap fs_14 text-white opacity-26"
                        type="text"
                      >
                        Search Your Game
                      </div> */}
                    <div className="py-2">
                      <img
                        style={{ width: "18px" }}
                        src={searchIcon}
                        alt="searchicon"
                      />
                    </div>
                  </div>
                </div>
              </div>
              {!isLogin ? (
                <div className="d-flex align-items-center pe-3 gap-2">
                  {/* <Link to={"/login"}> */}
                  <button
                    onClick={handleRegisterModal}
                    style={{ border: "2px solid #22b264" }}
                    className="bg-transparent border-2 px-3 py-2 rounded-2 text-white text-nowrap ff_heebo fw-medium fs_15"
                  >
                    Sign In
                  </button>
                  {/* </Link> */}
                  {/* <Link to={"/login"}> */}
                  <button
                    onClick={handleRegisterModal}
                    style={{
                      borderRadius: "2px",
                      border: "2px solid #22b264",
                    }}
                    className="text-white ff_heebo fw-medium fs_15 bg_clr_green px-3 py-2"
                  >
                    Register
                  </button>
                  {/* </Link> */}
                </div>
              ) : (
                <div className="d-flex justify-content-center">
                  <div></div>
                  <div className="d-flex gap-2 align-items-center w-100">
                    <div className="price_container p-1 rounded d-flex align-items-center bg-dark">
                      <div className="d-flex flex-column ps-2 pe-3 ">
                        <span className="currency ff_heebo fw-medium fs_12 text-uppercase">
                          inr
                        </span>
                        <span className="text-white ff_heebo fw-medium fs_12">
                          {Math.round(Number(UserBalance || 0)).toFixed(2)}
                        </span>
                      </div>
                      <div>
                        <button
                          style={{ borderRadius: "2px" }}
                          className="text-white ff_heebo fw-medium fs_12 bg_clr_green px-3 py-2 border-0 d-flex"
                          onClick={() => {
                            navigate("/deposit");
                          }}
                        >
                          Deposit
                        </button>
                      </div>
                    </div>
                    <div
                      className="cursor-pointer rounded"
                      onClick={() => {
                        setShowCanvas(true);
                      }}
                    >
                      <img src={UserLogo} width={40} height={40} />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <Row className="mt-lg-5 pt-lg-2">
            <Col lg={2}></Col>
            <Col lg={10}>
              <Outlet />
            </Col>
          </Row>
        </Col>
      </Row>
      <SearchModal
        open={openSearchModal}
        close={() => {
          setopenSearchModal(false);
        }}
      />
      <Offcanvas
        show={showCanvas}
        onHide={() => {
          setShowCanvas(false);
        }}
        backdropClassName="bg-dark"
        placement="end"
      >
        <Offcanvas.Header closeButton className="bg_clr_dark_blue_2">
          <Offcanvas.Title>
            <p className="text-white">Profile</p>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="bg_clr_dark_blue_2">
          <div className="pt-3 pb-4">
            <div className="container px-3">
              <h2 className="clr_soil mb-0 ff_inter fw-800 fs_22">
                My Profile
              </h2>
              {/* <div className="bg_clr_dark_grey_3 p-3 rounded my-3">
                <div className="d-flex flex-column align-items-center gap-2">
                  <div>
                    <img
                      src="https://easy-peasy.ai/cdn-cgi/image/quality=80,format=auto,width=700/https://fdczvxmwwjwpwbeeqcth.supabase.co/storage/v1/object/public/images/3077b9f6-85d4-4e22-8f27-750c0afa0251/d659562d-ffaa-4317-bf7e-d07333a4bd6d.png"
                      width={50}
                      className="rounded-circle"
                    />
                  </div>
                  <div className="text-white fs_14">
                    <span>{User?.fullName}</span>
                    <span className="mb-0 ps-2">{User?.lastName}</span>
                  </div>
                </div>
                <div>
                 
                  <div className="w-100 overflow-x-auto">
                    <Stepper
                      steps={[
                        { label: "Silver" },
                        { label: "Gold" },
                        { label: "Platinum" },
                        { label: "Diamond" },
                        { label: "Elite" },
                      ]}
                      activeStep={1}
                      className="text-white gap-3"
                    />
                  </div>
                  <ProgressBar
                    variant="success"
                    now={45}
                    className="range w-100 mt-3"
                  />
                  <p className="text-white mt-2 mb-0">
                    Complete 20K wagering by 01/Nov/2022 to reach Platinium .
                  </p>
                </div>
                <div>
                  <button className="btn btn-success mt-4 w-100">
                    See Your Benefits
                  </button>
                </div>
              </div> */}
              <div className="">
                <Link
                  to={"/bouns"}
                  className="ff_heebo fw-medium fs_16 mb-0 text-white ms-1"
                >
                  <div
                    onClick={() => {
                      setShowCanvas(false);
                    }}
                    className="d-flex align-items-center gap-2"
                  >
                    <img src={bonus} alt="bonus" />
                    Bonus
                  </div>{" "}
                </Link>
                <div className="profile-sec-hr-line my-3"></div>
                <Link to={"/app/transactions"}>
                  <div
                    onClick={() => {
                      setShowCanvas(false);
                    }}
                    className="d-flex align-items-center gap-2"
                  >
                    <img src={accountStatement} alt="bonus" />
                    <p className="ff_heebo fw-medium fs_16 mb-0 text-white ms-1">
                      Account Statement
                    </p>
                  </div>
                </Link>
                <div className="profile-sec-hr-line my-3"></div>
                <Link to={"/bet-history"}>
                  <div
                    onClick={() => {
                      setShowCanvas(false);
                    }}
                    className="d-flex align-items-center gap-2"
                  >
                    <img src={currentBetsAndHistory} alt="bonus" />
                    <p className="ff_heebo fw-medium fs_16 mb-0 text-white ms-1">
                      Current Bets & History
                    </p>
                  </div>
                </Link>
                <div className="profile-sec-hr-line my-3"></div>
                <div className="d-flex align-items-center gap-2">
                  <img src={activityLog} alt="bonus" />
                  <p className="ff_heebo fw-medium fs_16 mb-0 text-white ms-1">
                    Activity Log
                  </p>
                </div>
                <div className="profile-sec-hr-line my-3"></div>
                <div
                  className="d-flex cursor-pointer align-items-center gap-2"
                  onClick={() => {
                    navigate("/app/loyalty-point");
                    setShowCanvas(false);
                  }}
                >
                  <img src={casinoResults} alt="bonus" />
                  <p className="ff_heebo fw-medium fs_16 mb-0 text-white ms-1">
                    Loyalty Points
                  </p>
                </div>
                <div className="profile-sec-hr-line my-3"></div>
                <Link to={"/betHistory"}>
                  <div
                    onClick={() => {
                      setShowCanvas(false);
                    }}
                    className="d-flex  align-items-center gap-2"
                  >
                    <img src={liveCasinoBets} alt="bonus" />
                    <p className="ff_heebo fw-medium fs_16 mb-0 text-white ms-1">
                      Live Casino Bets
                    </p>
                  </div>
                </Link>
                <div className="profile-sec-hr-line my-3"></div>
                <Link to={"/sportsHistory"}>
                  <div
                    onClick={() => {
                      setShowCanvas(false);
                    }}
                    className="d-flex align-items-center gap-2"
                  >
                    <img src={sportBooksBets} alt="bonus" />
                    <p className="ff_heebo fw-medium fs_16 mb-0 text-white ms-1">
                      SportBook Bets
                    </p>
                  </div>
                </Link>
                {/* <div className="profile-sec-hr-line my-3"></div>
                <div className="d-flex align-items-center gap-2">
                  <img src={supportTicket} alt="bonus" />
                  <p className="ff_heebo fw-medium fs_16 mb-0 text-white ms-1">
                    Support Ticket
                  </p>
                </div>
                <div className="profile-sec-hr-line my-3"></div>
                <div className="d-flex align-items-center gap-2">
                  <img src={lock} alt="bonus" />
                  <p className="ff_heebo fw-medium fs_16 mb-0 text-white ms-1">
                    Change Password
                  </p>
                </div> */}
                <div className="profile-sec-hr-line my-3"></div>
                {/* <div className="d-flex align-items-center gap-2">
                  <img src={securityAuth} alt="bonus" />
                  <p className="ff_heebo fw-medium fs_16 mb-0 text-white ms-1">
                    Security Auth
                  </p>
                </div>
                <div className="profile-sec-hr-line my-3"></div>
                <div className="d-flex align-items-center gap-2">
                  <img src={verification} alt="bonus" />
                  <p className="ff_heebo fw-medium fs_16 mb-0 text-white ms-1">
                    Verification
                  </p>
                </div>
                <div className="profile-sec-hr-line my-3"></div> */}
                <div
                  className="d-flex cursor-pointer align-items-center gap-2"
                  onClick={Logout}
                >
                  <img src={logout} alt="bonus" />
                  <p className="ff_heebo fw-medium fs_16 mb-0 text-white ms-1">
                    Logout
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      <div id="Register_modal">
        <Modal
          size="md"
          centered
          show={showRegisterModal}
          onHide={handleRegisterModal}
        >
          <div className=" rounded-3 bg_clr_dark_blue_2">
            <Modal.Header className="text-black border-0 p-0" closeButton>
              {/* <Modal.Title>Register</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div className="pb-4 px-4">
                <Link to={"/"}>
                  <h1 className="text-center fst-italic mb-0 clr_green fs_48 fw-bolder">
                    45<span className=" text-white">X</span>
                  </h1>
                </Link>
                <p className=" text-center text-white">Play & Win</p>
                <div className="row justify-content-center align-items-center ">
                  <div className="row justify-content-center w-100 align-items-center ">
                    <div className="col-md-3 cursor-pointer px-1 col-3">
                      <div
                        onClick={() => handleClick("one-click")}
                        className={`text-center rounded-1 w-100 h-100 p-2 ${
                          activeButton === "one-click"
                            ? "bg_clr_green"
                            : "bg_clr_dark_grey_3"
                        }`}
                      >
                        <img width={20} src={on_click} alt="one_click" />
                        <p className="fs_11 text-white mb-0 pt-2">One Click</p>
                      </div>
                    </div>
                    <div className="col-md-3 cursor-pointer px-1 col-3">
                      <div
                        onClick={() => handleClick("phone-login")}
                        className={`text-center rounded-1 w-100 h-100 p-2 ${
                          activeButton === "phone-login"
                            ? "bg_clr_green"
                            : "bg_clr_dark_grey_3"
                        }`}
                      >
                        <img src={moble_icon} alt="phone_login" />
                        <p className="fs_11 text-white pt-2 mb-0">
                          Phone Login
                        </p>
                      </div>
                    </div>
                    <div className="col-md-3 cursor-pointer px-1 col-3">
                      <div
                        onClick={() => handleClick("social-login")}
                        className={`text-center rounded-1 w-100 h-100 p-2 ${
                          activeButton === "social-login"
                            ? "bg_clr_green"
                            : "bg_clr_dark_grey_3"
                        }`}
                      >
                        <img src={social_icon} alt="social_login" />
                        <p className="fs_11 text-white pt-2 mb-0">
                          Social Login
                        </p>
                      </div>
                    </div>
                    <div className="col-md-3 cursor-pointer px-1 col-3">
                      <div
                        onClick={() => handleClick("mail-login")}
                        className={`text-center rounded-1 w-100 h-100 p-2 ${
                          activeButton === "mail-login"
                            ? "bg_clr_green"
                            : "bg_clr_dark_grey_3"
                        }`}
                      >
                        <img src={mail_icon} alt="mail_login" />
                        <p className="fs_11 text-white pt-2 mb-0">Mail Login</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border mt-4"></div>
                <div>{renderComponent()}</div>
              </div>
            </Modal.Body>
          </div>
          {/* <Modal.Footer>
            <Button variant="secondary" onClick={handleRegisterModal}>
              Close
            </Button>
            <Button variant="primary">Register</Button>
          </Modal.Footer> */}
        </Modal>
      </div>
    </div>
  );
}

export default DestopMainLayout;
