import React, { useEffect } from 'react';
import './theme.css';
import { useParams } from 'react-router-dom';

const ScoreBoard = () => {
  const { id } = useParams();
  
  // Function to extract numbers from the match ID
  function extractNumbers(input) {
    const digits = input.match(/\d+/g);
    if (digits === null) {
      return '';
    }
    return digits.join('');
  }

  useEffect(() => {
    if (id) {
      const loadScript = () => {
        // Updated widget loader script with new URL
        (function (a, b, c, d, e, f, g, h, i) {
          if (!a[e]) {
            i = a[e] = function () {
              (a[e].q = a[e].q || []).push(arguments);
            };
            i.l = 1 * new Date();
            i.o = f;
            g = b.createElement(c);
            h = b.getElementsByTagName(c)[0];
            g.async = 1;
            g.src = d;
            g.setAttribute("n", e);
            h.parentNode.insertBefore(g, h);
          }
        })(window, document, "script", "https://widgets.sir.sportradar.com/6534dccecd9787ef870fb83c284a8a20/widgetloader", "SIR", {
          theme: false, // using custom theme
          language: "en",
        });
      };

      loadScript();

      const addWidget = () => {
        if (window.SIR) {
          // Adding the updated widget
          window.SIR("addWidget", ".sr-widget-1", "match.lmtPlus", {
            layout: "topdown",
            matchId: extractNumbers(id), // Extracting numbers from the match ID
          });
        }
      };

      addWidget();
    }
  }, [id]);

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div className="widgets scoreBorad">
        <div className="sr-widget sr-widget-1" style={{ border: 'rgba(0, 0, 0, 0.12) solid 1px', marginBottom: '24px' }}>
          <div className="sr-widget-title" style={{ fontWeight: 'bold', paddingBottom: '4px' }}>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScoreBoard;