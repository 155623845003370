// import React, { useEffect, useState } from "react";
// import {
//   GetAllDepositRequest,
//   GetAllWidtharalRequest,
// } from "../../Services/Transactions";
// import { getLocalDate, getLocalTime } from "../../Services/Common";
// import { Modal } from "react-bootstrap";
// import cross_icon from "../../assets/images/svg/cross_icon.svg";
// import eye from "../../assets/images/png/eye.png";
// function Transactions() {
//   const [activeTab, setActiveTab] = useState("deposit"); // Initial tab
//   const [depositHistory, setDepositHistory] = useState([]);
//   const [withdrawalHistory, setWithdrawalHistory] = useState([]);
//   const [showImage, setShowImage] = useState(false);
//   const [selectedImage, setSelectedImage] = useState("");
//   const baseurl = process.env.REACT_APP_BASEURL;
//   const handleTabClick = (tab) => {
//     setActiveTab(tab);
//   };

//   const getDepositHistory = async () => {
//     const data = {
//       payment_status: "", // ["pending", "completed", "canceled", "rejected"]
//       start_date: "",
//       end_date: "",
//       page: 1,
//       limit: 10,
//     };
//     await GetAllDepositRequest(data).then((res) => {
//       setDepositHistory(res?.data);
//     });
//   };

//   const getWithdrawalHistory = async () => {
//     const data = {
//       payment_status: "", // ["pending", "completed", "canceled", "rejected"]
//       start_date: "",
//       end_date: "",
//       page: 1,
//       limit: 10,
//     };
//     await GetAllWidtharalRequest(data).then((res) => {
//       setWithdrawalHistory(res?.data);
//     });
//   };

//   useEffect(() => {
//     switch (activeTab) {
//       case "deposit":
//         getDepositHistory();
//         break;
//       case "withdrawal":
//         getWithdrawalHistory();
//         break;
//       default:
//         break;
//     }
//   }, [activeTab]);

//   const openModal = (item) => {
//     setShowImage(true);
//     setSelectedImage(item?.screenshot);
//   };

//   return (
//     <div className="container py-3">
//       <div>
//         <h5 className="fs_16 fw-medium ff_heebo text-white">Transactions</h5>

//         <div className="d-flex align-items-center justify-content-between gap-3 mt-3">
//           <button
//             className={`py-2 ff_heebo ${
//               activeTab === "deposit"
//                 ? "clr_green bg_clr_green green_border text-white"
//                 : "bg-transparent green_border clr_green"
//             } rounded-1 w-100`}
//             onClick={() => handleTabClick("deposit")}
//           >
//             Deposit
//           </button>
//           <button
//             className={`py-2 ff_heebo ${
//               activeTab === "withdrawal"
//                 ? "clr_green bg_clr_green green_border text-white"
//                 : "bg-transparent green_border clr_green"
//             } rounded-1 w-100`}
//             onClick={() => handleTabClick("withdrawal")}
//           >
//             Withdrawal
//           </button>
//         </div>

//         {activeTab === "deposit" ? (
//           <>
//             {" "}
//             <div className="d-flex justify-content-end my-4 gap-3 align-items-end">
//               <div className="d-flex flex-column">
//                 <label className="text-white ff_heebo" htmlFor="">
//                   Start Date
//                 </label>
//                 <input
//                   className="mt-1 px-2 py-2 rounded-2"
//                   type="date"
//                   id="startDate"
//                   name="startDate" // Use name attribute to differentiate inputs
//                   // value={dateRange.startDate}
//                   // onChange={handleDateChange}
//                 />
//               </div>
//               <div className="d-flex flex-column">
//                 <label className="text-white ff_heebo" htmlFor="">
//                   End Date
//                 </label>
//                 <input
//                   className="mt-1 px-2 py-2 rounded-2"
//                   type="date"
//                   id="endDate"
//                   name="endDate" // Use name attribute to differentiate inputs
//                   // value={dateRange.endDate}
//                   // onChange={handleDateChange}
//                 />
//               </div>
//               <select
//                 className=" rounded-2 py-2 px-4"
//                 name="status"
//                 id=""
//                 // onChange={handleDateChange}
//               >
//                 <option value="">All</option>
//                 <option value="credit">Credit</option>
//                 <option value="debit">Debit</option>
//               </select>
//             </div>
//             <div>
//               {depositHistory.length > 0 ? (
//                 depositHistory.map((item, index) => (
//                   <div
//                     key={index}
//                     className="ps-3 pe-5 py-3 ff_heebo text-white bg_clr_dark_grey_3 rounded-1 border-0 w-100 mt-3 d-flex justify-content-between align-items-center"
//                     onClick={() => {
//                       if (item?.payment_method !== "bonus") {
//                         openModal(item);
//                       }
//                     }}
//                   >
//                     <div className="d-flex flex-column">
//                       <div className="d-flex align-items-center gap-2">
//                         <span className="fs_16 fw-medium ff_heebo ">
//                           {item?.amount}
//                         </span>
//                         <span className="fs_12 fw-medium ff_heebo ">
//                           ({item.payment_method})
//                         </span>
//                         {item?.payment_method !== "bonus" && (
//                           <img src={eye} width={15} />
//                         )}
//                       </div>
//                       <span className="fs_10 fw-medium ff_heebo text-white">
//                         {getLocalDate(item.createdAt) +
//                           " " +
//                           `(${getLocalTime(item.createdAt)})`}
//                       </span>
//                     </div>
//                     <div
//                       className={`fs_14 fw-medium ff_heebo text-capitalize ${
//                         item?.status === "completed"
//                           ? "clr_green"
//                           : item?.status === "rejected"
//                           ? "clr_red"
//                           : "clr_yellow"
//                       }`}
//                     >
//                       {item?.status}
//                     </div>
//                   </div>
//                 ))
//               ) : (
//                 <div>
//                   <h1 className="fs_16 fw-medium ff_heebo text-white text-center mt-4">
//                     No Deposit Found
//                   </h1>
//                 </div>
//               )}
//             </div>
//           </>
//         ) : (
//           <div>
//             {withdrawalHistory.length > 0 ? (
//               withdrawalHistory.map((item, index) => (
//                 <div
//                   key={index}
//                   className="ps-3 pe-5 py-3 ff_heebo text-white bg_clr_dark_grey_3 rounded-1 border-0 w-100 mt-3 d-flex justify-content-between align-items-center"
//                 >
//                   <div className="d-flex flex-column">
//                     <span className="fs_16 fw-medium ff_heebo ">
//                       {item.amount}
//                     </span>
//                     <span className="fs_10 fw-medium ff_heebo text-white">
//                       {getLocalDate(item.createdAt) +
//                         " " +
//                         `(${getLocalTime(item.createdAt)})`}
//                     </span>
//                   </div>
//                   <div
//                     className={`fs_14 fw-medium ff_heebo text-capitalize ${
//                       item?.status === "completed"
//                         ? "clr_green"
//                         : item?.status === "rejected"
//                         ? "clr_red"
//                         : "clr_yellow"
//                     }`}
//                   >
//                     {item?.status}
//                   </div>
//                 </div>
//               ))
//             ) : (
//               <div>
//                 <h1 className="fs_16 fw-medium ff_heebo text-white text-center mt-4">
//                   No Withdrawal Found
//                 </h1>
//               </div>
//             )}
//           </div>
//         )}
//       </div>

//       <Modal
//         className="bg_modal"
//         show={showImage}
//         onHide={() => setShowImage(false)}
//         centered
//       >
//         <Modal.Body className="text-center text-white">
//           <div className="bg_clr_green px-3 py-5 mt-4 rounded-1 position-relative">
//             <div
//               onClick={() => setShowImage(false)}
//               className="position-absolute end-0 top-0"
//             >
//               <img className="pe-2 pt-2" src={cross_icon} alt="cross_icon" />
//             </div>
//             <div>
//               <img
//                 src={`${baseurl}/image/${selectedImage}`}
//                 className="w-100"
//                 alt="Transaction Screenshot"
//               />
//             </div>
//           </div>
//         </Modal.Body>
//       </Modal>
//     </div>
//   );
// }

// export default Transactions;

import React, { useEffect, useState } from "react";
import {
  GetAllDepositRequest,
  GetAllWidtharalRequest,
} from "../../Services/Transactions";
import { getLocalDate, getLocalTime } from "../../Services/Common";
import { Modal } from "react-bootstrap";
import cross_icon from "../../assets/images/svg/cross_icon.svg";
import eye from "../../assets/images/png/eye.png";

function Transactions() {
  const [activeTab, setActiveTab] = useState("deposit"); // Initial tab
  const [depositHistory, setDepositHistory] = useState([]);
  const [withdrawalHistory, setWithdrawalHistory] = useState([]);
  const [showImage, setShowImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const baseurl = process.env.REACT_APP_BASEURL;

  // Filter states
  const [filters, setFilters] = useState({
    start_date: "",
    end_date: "",
    payment_status: "",
  });

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  const getDepositHistory = async () => {
    const data = {
      payment_status: filters.payment_status || "", // ["pending", "completed", "canceled", "rejected"]
      start_date: filters.start_date || "",
      end_date: filters.end_date || "",
      page: 1,
      limit: 10,
    };
    await GetAllDepositRequest(data).then((res) => {
      console.log(res.data);
      setDepositHistory(res?.data);
    });
  };

  const getWithdrawalHistory = async () => {
    const data = {
      payment_status: filters.payment_status || "", // ["pending", "completed", "canceled", "rejected"]
      start_date: filters.start_date || "",
      end_date: filters.end_date || "",
      page: 1,
      limit: 10,
    };
    await GetAllWidtharalRequest(data).then((res) => {
      setWithdrawalHistory(res?.data);
    });
  };

  useEffect(() => {
    switch (activeTab) {
      case "deposit":
        getDepositHistory();
        break;
      case "withdrawal":
        getWithdrawalHistory();
        break;
      default:
        break;
    }
  }, [activeTab, filters]); // Refetch data when filters change

  const openModal = (item) => {
    setShowImage(true);
    setSelectedImage(item?.screenshot);
  };

  return (
    <div className="container py-3">
      <div>
        <h5 className="fs_16 fw-medium ff_heebo text-white">Transactions</h5>

        <div className="d-flex align-items-center justify-content-between gap-3 mt-3">
          <button
            className={`py-2 ff_heebo ${
              activeTab === "deposit"
                ? "clr_green bg_clr_green green_border text-white"
                : "bg-transparent green_border clr_green"
            } rounded-1 w-100`}
            onClick={() => handleTabClick("deposit")}
          >
            Deposit
          </button>
          <button
            className={`py-2 ff_heebo ${
              activeTab === "withdrawal"
                ? "clr_green bg_clr_green green_border text-white"
                : "bg-transparent green_border clr_green"
            } rounded-1 w-100`}
            onClick={() => handleTabClick("withdrawal")}
          >
            Withdrawal
          </button>
        </div>

        <div className="d-flex justify-content-end my-4 gap-3 align-items-end">
          <div className="d-flex flex-column">
            <label className="text-white ff_heebo" htmlFor="startDate">
              Start Date
            </label>
            <input
              className="mt-1 px-2 py-2 rounded-2"
              type="date"
              id="startDate"
              name="start_date"
              value={filters.start_date}
              onChange={handleFilterChange}
            />
          </div>
          <div className="d-flex flex-column">
            <label className="text-white ff_heebo" htmlFor="endDate">
              End Date
            </label>
            <input
              className="mt-1 px-2 py-2 rounded-2"
              type="date"
              id="endDate"
              name="end_date"
              value={filters.end_date}
              onChange={handleFilterChange}
            />
          </div>
          <select
            className="rounded-2 py-2 px-4"
            name="payment_status"
            value={filters.payment_status}
            onChange={handleFilterChange}
          >
            <option value="">All</option>
            <option value="pending">Pending</option>
            <option value="completed">Completed</option>
            <option value="canceled">Canceled</option>
            <option value="rejected">Rejected</option>
          </select>
        </div>

        {activeTab === "deposit" ? (
          <div>
            {depositHistory.length > 0 ? (
              depositHistory.map((item, index) => (
                <div
                  key={index}
                  className="ps-3 pe-5 py-3 ff_heebo text-white bg_clr_dark_grey_3 rounded-1 border-0 w-100 mt-3 d-flex justify-content-between align-items-center"
                  onClick={() => {
                    if (item?.payment_method !== "bonus") {
                      openModal(item);
                    }
                  }}
                >
                  <div className="d-flex flex-column">
                    <div className="d-flex align-items-center gap-2">
                      <span className="fs_16 fw-medium ff_heebo ">
                        {item?.amount}
                      </span>
                      <span className="fs_12 fw-medium ff_heebo ">
                        ({item.payment_method})
                      </span>
                      {item?.payment_method !== "bonus" && (
                        <img src={eye} width={15} alt="eye" />
                      )}
                    </div>
                    <span className="fs_10 fw-medium ff_heebo text-white">
                      {getLocalDate(item.createdAt) +
                        " " +
                        `(${getLocalTime(item.createdAt)})`}
                    </span>
                  </div>
                  <div
                    className={`fs_14 fw-medium ff_heebo text-capitalize ${
                      item?.status === "completed"
                        ? "clr_green"
                        : item?.status === "rejected"
                        ? "clr_red"
                        : "clr_yellow"
                    }`}
                  >
                    {item?.status}
                  </div>
                </div>
              ))
            ) : (
              <div>
                <h1 className="fs_16 fw-medium ff_heebo text-white text-center mt-4">
                  No Deposit Found
                </h1>
              </div>
            )}
          </div>
        ) : (
          <div>
            {withdrawalHistory.length > 0 ? (
              withdrawalHistory.map((item, index) => (
                <div
                  key={index}
                  className="ps-3 pe-5 py-3 ff_heebo text-white bg_clr_dark_grey_3 rounded-1 border-0 w-100 mt-3 d-flex justify-content-between align-items-center"
                >
                  <div className="d-flex flex-column">
                    <span className="fs_16 fw-medium ff_heebo ">
                      {item.amount}
                    </span>
                    <span className="fs_10 fw-medium ff_heebo text-white">
                      {getLocalDate(item.createdAt) +
                        " " +
                        `(${getLocalTime(item.createdAt)})`}
                    </span>
                  </div>
                  <div
                    className={`fs_14 fw-medium ff_heebo text-capitalize ${
                      item?.status === "completed"
                        ? "clr_green"
                        : item?.status === "rejected"
                        ? "clr_red"
                        : "clr_yellow"
                    }`}
                  >
                    {item?.status}
                  </div>
                </div>
              ))
            ) : (
              <div>
                <h1 className="fs_16 fw-medium ff_heebo text-white text-center mt-4">
                  No Withdrawal Found
                </h1>
              </div>
            )}
          </div>
        )}
      </div>

      <Modal
        className="bg_modal"
        show={showImage}
        onHide={() => setShowImage(false)}
        centered
      >
        <Modal.Body className="text-center text-white">
          <div className="bg_clr_green px-3 py-5 mt-4 rounded-1 position-relative">
            <div
              onClick={() => setShowImage(false)}
              className="position-absolute end-0 top-0"
            >
              <img className="pe-2 pt-2" src={cross_icon} alt="cross_icon" />
            </div>
            <div>
              <img
                src={`${baseurl}/image/${selectedImage}`}
                className="w-100"
                alt="Transaction Screenshot"
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Transactions;
