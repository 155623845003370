import React, { useEffect, useState } from "react";
import cricket from "../../assets/images/svg/cricketStumps.svg";
import arrowRight from "../../assets/images/svg/arrowRight.svg";
import mi from "../../assets/images/png/mumbaiIndians.png";
import dc from "../../assets/images/png/delhiCapitals.png";
import BetSlip from "../bottomnav/BetSlip";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GetDateDescription, TimePending } from "../../Services/Common";
import { setEvent } from "../../redux/SingleEvents/SelectedEvent";
import { GetAllEvents } from "../../redux/AllEvent/EventSlice";
import Skeletion from "../skeletion/Skeletion";
import { getAllLiveSportsEvents } from "../../Services/events";
import VsLogo from "../../assets/images/png/versus.png"
const LiveSports = () => {
  const [isBetting, setIsBetting] = useState(false);
  const [liveEvents, setLiveEvents] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  if (!isBetting) {
    document.body.style.overflow = "visible";
  } else {
    document.body.style.overflow = "hidden";
  }

  useEffect(() => {
    getAllLiveSportsEvents().then(res => {
      console.log("Live sports", res)
      if (res.data) {
        setLiveEvents(res?.data)
      }
    })
  }, [])

  const TimePending2 = (d) => {
    const now = new Date();
    const target = new Date(d);
    const diff = target - now;

    if (diff < 0) {
      return "Live";
    }

    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

    return `${days} days ${hours} hours ${minutes} minutes`;
  }

  return (
    <div className="ps-3">
      {liveEvents?.length > 0 && (
        <div className="container ps-0">
          <div className="d-flex align-items-center justify-content-between mt-4">
            <div className="d-flex align-items-center gap-2">
              <img src={cricket} alt="stumps" />
              <p className="mb-0 ff_inter fs_19 text-white fw-medium">Live</p>
            </div>
            {/* <div className="d-flex align-items-center gap-2">
              <p className="text-white mb-0 fs_12 fw-medium ff_inter">
                Upcoming Events
              </p>
              <img src={arrowRight} alt="rightArrow" />
            </div> */}
          </div>
          <div className="d-flex gap-3 overflow-auto matches-box-div">
            {liveEvents?.length > 0 ? liveEvents?.filter(
              (value) => TimePending(value?.event?.startDate) !== "ENDED"
            )?.map((value, index) => (
              <div
                key={index}
                className="matches-div rounded-2 px-3 py-4 h-100 mt-3"
                onClick={() => {
                  dispatch(setEvent(value));
                  if (value.sport_name == "Cricket") {
                    navigate(`/sport_details/${value?.id}/circket/${value?.next_live_time}/${value?.betfair_event_id}`);
                  } else {
                    navigate("/sport_details/" + value.id + "/" + value.sport_name);
                  }
                }}
              >
                <div>
                  <div className="d-flex justify-content-between w-100">
                    <p className="text-white mb-0 ff_inter fw-medium w-75 fs_12">
                      {value?.match_name}
                    </p>
                    <div className="d-flex flex-column align-items-center gap-2">
                      <p className="text-white mb-0 ff_inter fw-medium text-nowrap fs_12">
                        {value?.sport_name}
                      </p>
                      <div className="text-center d-flex gap-3">
                        {TimePending2(value?.next_live_time) !== "Live" && <p className="mb-0 text-white ff_inter fs_11 fw-medium">
                          Start in
                        </p>}
                        {
                          TimePending2(value?.next_live_time) == "Live" ?
                            <p className="mb-0 bg-danger text-white px-2 rounded ff_inter fs_11 fw-bold">
                              {value.status == "closed" ? "Ended" : TimePending2(value?.next_live_time)}
                            </p>
                            :
                            <p className="mb-0 clr_green ff_inter fs_11 fw-bold">
                              {value.status == "closed" ? "Ended" : TimePending(value?.next_live_time)}
                            </p>
                        }
                      </div>
                    </div>

                  </div>
                  <div className="events-hr-line mt-3"></div>
                  <div className="d-flex align-items-center justify-content-between mt-4 ">
                    <div className="text-center">
                      <img
                        style={{ width: "48px" }}
                        src={mi}
                        alt="mumbaiIndians"
                      />
                      <p className="mb-0 text-white ff_inter fs_11 fw-medium mt-3">
                        {value?.team1_name}
                      </p>
                    </div>
                    <div>
                      <img
                        style={{ width: "48px" }}
                        src={VsLogo}
                        alt="mumbaiIndians"
                      />

                    </div>
                    <div className="text-center">
                      <img
                        style={{ width: "30px" }}
                        src={dc}
                        alt="delhiCapitals"
                      />
                      <p className="mb-0 text-white ff_inter fs_11 fw-medium mt-3">
                        {value?.team2_name}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )) :
              <div className="mt-3 d-flex flex-column justify-content-center gap-2 align-items-center">
                <Skeletion height={"210px"} width={"350px"} borderRadius={5} />
                <Skeletion height={"210px"} width={"350px"} borderRadius={5} />
                <Skeletion height={"210px"} width={"350px"} borderRadius={5} />
                <Skeletion height={"210px"} width={"350px"} borderRadius={5} />
              </div>
            }
          </div>
          {/* <div className="overflow-auto matches-box-div">
            {Cricket?.length > 0 ? (
              <div className="mt-3 d-flex align-items-center justify-content-between gap-3">
                {liveEvents.map((value, index) => (
                  <div
                    key={index}
                    className="matches-div rounded-2 px-3 py-4"
                    onClick={() => {
                      dispatch(setEvent(value));
                      navigate("/Betting");
                    }}
                  >
                    <div>
                      <div className="d-flex justify-content-between align-items-center">
                        <p className="text-white mb-0 ff_inter fw-medium fs_12">
                          {value?.series?.name}
                        </p>
                        <div>
                          <span className="bg-danger rounded-3 px-3 py-1 text-white mb-0 ff_inter fw-medium fs_12 text-uppercase">
                            live
                          </span>
                        </div>
                      </div>
                      <div className="events-hr-line mt-3"></div>
                      <div className="d-flex align-items-center justify-content-between mt-4 ">
                        <div className="text-center">
                          <img
                            style={{ width: "48px" }}
                            src={mi}
                            alt="mumbaiIndians"
                          />
                          <p className="mb-0 text-white ff_inter fs_11 fw-medium mt-3">
                            {value?.runners[0]?.name}
                          </p>
                        </div>
                        <div className="text-center">
                          <p className="mb-0 text-white ff_inter fs_11 fw-medium">
                            {GetDateDescription(value?.event?.startDate)}
                          </p>
                          <p className="mb-0 clr_green ff_inter fs_19 fw-bold">
                            {TimePending(value?.event?.startDate)}
                          </p>
                        </div>
                        <div className="text-center">
                          <img
                            style={{ width: "48px" }}
                            src={dc}
                            alt="delhiCapitals"
                          />
                          <p className="mb-0 text-white ff_inter fs_11 fw-medium mt-3">
                            {value?.runners[1]?.name}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between gap-3 mt-3">
                      <div
                        onClick={() => setIsBetting(true)}
                        className="bg_clr_dark_blue_2 betting-info-div rounded-2 w-100 d-flex flex-column align-items-center justify-content-center"
                      >
                        <p className="text-white ff_inter fw-bold fs_15 mb-0">
                          1.01
                        </p>
                        <p className="clr_grey ff_inter fw-bold fs_12 mb-0">
                          1
                        </p>
                      </div>
                      <div
                        onClick={() => setIsBetting(true)}
                        className="bg_clr_dark_blue_2 betting-info-div rounded-2 w-100 d-flex flex-column align-items-center justify-content-center"
                      >
                        <p className="text-white ff_inter fw-bold fs_15 mb-0">
                          1.01
                        </p>
                        <p className="clr_grey ff_inter fw-bold fs_12 mb-0">
                          1
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="mt-3 d-flex gap-2">
                <Skeletion height={180} width={290} borderRadius={5} />
                <Skeletion height={180} width={290} borderRadius={5} />
                <Skeletion height={180} width={290} borderRadius={5} />
              </div>
            )}
          </div> */}
          <div
            onClick={() => setIsBetting(false)}
            className={`body-overlay ${isBetting === true ? "d-block" : "d-none"
              }`}
          ></div>
          <BetSlip
            active={isBetting}
            setIsBettingFalse={() => setIsBetting(false)}
          />
        </div>
      )}
    </div>
  );
};

export default LiveSports;
