import {
  createSlice,
  configureStore,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { getAllSports } from "../../Services/events";
import { GetSportsCategory } from "../../Services/SportsRader";
import { GetSideBarData } from "../../Services/SportsRadar";

// Define the async thunk
export const GetAllEvents = createAsyncThunk("allEvents/events", async () => {
  const response = await getAllSports();
  return response; // assuming response is in the correct format
});

export const GetAllSportsCategory = createAsyncThunk(
  "allEvents/categories",
  async () => {
    const response = await GetSportsCategory();
    return response; // assuming response is in the correct format
  }
);

export const GetAllSideBarData = createAsyncThunk(
  "allEvents/GetSideBarData",
  async () => {
    const response = await GetSideBarData();
    return response; // assuming response is in the correct format
  }
);

const EventSlice = createSlice({
  name: "allEvents",
  initialState: {
    events: [],
    categories: [],
    sideBar: [],
    error: null,
    loading: true,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(GetAllEvents.fulfilled, (state, action) => {
      state.events = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(GetAllSportsCategory.fulfilled, (state, action) => {
      state.categories = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(GetAllSideBarData.fulfilled, (state, action) => {
      state.sideBar = action.payload;
      state.loading = false;
      state.error = null;
    });
  },
});
export default EventSlice.reducer;
