import React from "react";
import { Col, Row } from "react-bootstrap";
import goToArrow from "../../assets/images/svg/goToArrowIcon.svg";
import Sponsers from "./Sponsers";

const Appfooter = () => {
  return (
    <div className="mt-4 pt-1">
      <div className="container">
        <h2 className="ff_inter fw-semibold fs_16 text-white mb-0">
          Disclaimer
        </h2>
        <p className="ff_inter fw-semibold fs_13 text-white mb-0 mt-3">
          Please gamble responsibly. Our website is intended for users aged 18
          and above. Gambling can be addictive; please play responsibly.
        </p>
        <div>
          <Row className="">
            <Col className="" xs={6} lg={3}>
              <ul className="p-0 m-0 mt-4">
                <li className="text-white fw-semibold fs_13">Sports</li>
                <li className="mt-2">
                  <a
                    className="ff_inter fw-semibold fs_12 clr_dark_grey"
                    href="#"
                  >
                    Sportsbook
                  </a>
                </li>
                <li className="mt-1">
                  <a
                    className="ff_inter fw-semibold fs_12 clr_dark_grey"
                    href="#"
                  >
                    Live Sports
                  </a>
                </li>
                <li className="mt-1">
                  <a
                    className="ff_inter fw-semibold fs_12 clr_dark_grey"
                    href="#"
                  >
                    Soccer
                  </a>
                </li>
                <li className="mt-1">
                  <a
                    className="ff_inter fw-semibold fs_12 clr_dark_grey"
                    href="#"
                  >
                    Basketball
                  </a>
                </li>
                <li className="mt-1">
                  <a
                    className="ff_inter fw-semibold fs_12 clr_dark_grey"
                    href="#"
                  >
                    Tennis
                  </a>
                </li>
                <li className="mt-1">
                  <a
                    className="ff_inter fw-semibold fs_12 clr_dark_grey"
                    href="#"
                  >
                    eSports
                  </a>
                </li>
                <li className="mt-1">
                  <a
                    className="ff_inter fw-semibold fs_12 clr_dark_grey"
                    href="#"
                  >
                    Bet Bonuses
                  </a>
                </li>
                <li className="mt-1">
                  <a
                    className="ff_inter fw-semibold fs_12 clr_dark_grey"
                    href="#"
                  >
                    Sports Rules
                  </a>
                </li>
              </ul>
            </Col>
            <Col className="" xs={6} lg={3}>
              <ul className="p-0 m-0 mt-4">
                <li className="text-white fw-semibold fs_13">Casino</li>
                <li className="mt-2">
                  <a
                    className="ff_inter fw-semibold fs_12 clr_dark_grey"
                    href="#"
                  >
                    Casino Games
                  </a>
                </li>
                <li className="mt-1">
                  <a
                    className="ff_inter fw-semibold fs_12 clr_dark_grey"
                    href="#"
                  >
                    Slots
                  </a>
                </li>
                <li className="mt-1">
                  <a
                    className="ff_inter fw-semibold fs_12 clr_dark_grey"
                    href="#"
                  >
                    Live Casino
                  </a>
                </li>
                <li className="mt-1">
                  <a
                    className="ff_inter fw-semibold fs_12 clr_dark_grey"
                    href="#"
                  >
                    Roulette
                  </a>
                </li>
                <li className="mt-1">
                  <a
                    className="ff_inter fw-semibold fs_12 clr_dark_grey"
                    href="#"
                  >
                    Blackjack
                  </a>
                </li>
                <li className="mt-1">
                  <a
                    className="ff_inter fw-semibold fs_12 clr_dark_grey"
                    href="#"
                  >
                    Providers
                  </a>
                </li>
                <li className="mt-1">
                  <a
                    className="ff_inter fw-semibold fs_12 clr_dark_grey"
                    href="#"
                  >
                    VIP Club
                  </a>
                </li>
                <li className="mt-1">
                  <a
                    className="ff_inter fw-semibold fs_12 clr_dark_grey"
                    href="#"
                  >
                    Promotions
                  </a>
                </li>
              </ul>
            </Col>
            <Col className=" mt-4" xs={6} lg={3}>
              <ul className="p-0 m-0 mt-lg-0 mt-4">
                <li className="text-white fw-semibold fs_13">Support</li>
                <li className="mt-2">
                  <a
                    className="ff_inter fw-semibold fs_12 clr_dark_grey"
                    href="#"
                  >
                    Fairness
                  </a>
                </li>
                <li className="mt-1">
                  <a
                    className="ff_inter fw-semibold fs_12 clr_dark_grey"
                    href="#"
                  >
                    Affiliate
                  </a>
                </li>
                <li className="mt-1">
                  <a
                    className="ff_inter fw-semibold fs_12 clr_dark_grey"
                    href="#"
                  >
                    Responsible Gambling
                  </a>
                </li>
                <li className="mt-1">
                  <a
                    className="ff_inter fw-semibold fs_12 clr_dark_grey"
                    href="#"
                  >
                    Gamble Aware
                    <img className="ms-2" src={goToArrow} alt="arrow" />
                  </a>
                </li>
                <li className="mt-1">
                  <a
                    className="ff_inter fw-semibold fs_12 clr_dark_grey"
                    href="#"
                  >
                    Live Support
                  </a>
                </li>
                <li className="mt-1">
                  <a
                    className="ff_inter fw-semibold fs_12 clr_dark_grey"
                    href="#"
                  >
                    Help Center
                    <img className="ms-2" src={goToArrow} alt="arrow" />
                  </a>
                </li>
                <li className="mt-1">
                  <a
                    className="ff_inter fw-semibold fs_12 clr_dark_grey"
                    href="#"
                  >
                    How to Guides
                  </a>
                </li>
                <li className="mt-1">
                  <a
                    className="ff_inter fw-semibold fs_12 clr_dark_grey"
                    href="#"
                  >
                    Local Currency Guide
                  </a>
                </li>
                <li className="mt-1">
                  <a
                    className="ff_inter fw-semibold fs_12 clr_dark_grey"
                    href="#"
                  >
                    Supported Crypto
                  </a>
                </li>
              </ul>
            </Col>
            <Col className=" mt-4" xs={6} lg={3}>
              <ul className="p-0 m-0 mt-lg-0 mt-4">
                <li className="text-white fw-semibold fs_13">Community</li>
                <li className="mt-2">
                  <a
                    className="ff_inter fw-semibold fs_12 clr_dark_grey"
                    href="#"
                  >
                    Blog
                  </a>
                </li>
                <li className="mt-1">
                  <a
                    className="ff_inter fw-semibold fs_12 clr_dark_grey"
                    href="#"
                  >
                    Forum
                    <img className="ms-2" src={goToArrow} alt="arrow" />
                  </a>
                </li>
                <li className="mt-1">
                  <a
                    className="ff_inter fw-semibold fs_12 clr_dark_grey"
                    href="#"
                  >
                    Facebook
                    <img className="ms-2" src={goToArrow} alt="arrow" />
                  </a>
                </li>
                <li className="mt-1">
                  <a
                    className="ff_inter fw-semibold fs_12 clr_dark_grey"
                    href="#"
                  >
                    Twitter
                    <img className="ms-2" src={goToArrow} alt="arrow" />
                  </a>
                </li>
                <li className="mt-1">
                  <a
                    className="ff_inter fw-semibold fs_12 clr_dark_grey"
                    href="#"
                  >
                    Instagram
                    <img className="ms-2" src={goToArrow} alt="arrow" />
                  </a>
                </li>
                <li className="mt-1">
                  <a
                    className="ff_inter fw-semibold fs_12 clr_dark_grey"
                    href="#"
                  >
                    YouTube
                    <img className="ms-2" src={goToArrow} alt="arrow" />
                  </a>
                </li>
                <li className="mt-1">
                  <a
                    className="ff_inter fw-semibold fs_12 clr_dark_grey"
                    href="#"
                  >
                    Shop
                    <img className="ms-2" src={goToArrow} alt="arrow" />
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
          <Sponsers />
        </div>
      </div>
    </div>
  );
};

export default Appfooter;
