import React, { useEffect, useState } from "react";
import slotsIcon from "../../assets/images/svg/slot_icon.svg";
import dot_icon from "../../assets/images/svg/dot_icon.svg";
import cross_icon from "../../assets/images/svg/cross_icon.svg";

import { Link, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";

const CasinoSlots = ({ event, Categorie, active, Home = false }) => {
  const [FunModeModal, setFunModeModal] = useState(false);
  const navigate = useNavigate();
  const User = useSelector((state) => state.User.User);

  function showTwoWordsWithEllipsis(text) {
    // Split the text by spaces into an array of words
    const words = text.split(" ");

    // Check if there are more than two words
    if (words.length > 2) {
      // Join the first two words and add "..."
      return words.slice(0, 2).join(" ") + " ...";
    }

    // If there are two or fewer words, return the text as is
    return text;
  }
  return (
    <div className="pb-4 pt-0">
      {Categorie?.length > 0 ? (
        Categorie[active]?.name == "all" ? (
          <>
            {Categorie?.filter((_, index) => (Home ? index < 4 : true)).map(
              (items, index) => {
                return (
                  <>
                    {index !== 0 && (
                      <div className="pt-4">
                        <div className="container">
                          <div className="">
                            <div className="d-flex align-items-center gap-2">
                              <img width={20} src={slotsIcon} alt="football" />
                              <p className="text-white mb-0 ff_inter fw-medium fs_16">
                                {items?.name}
                              </p>
                            </div>
                          </div>
                          <div className="container ms-auto overflow-auto px-0 sports-casino-scrollbar">
                            <div className="">
                              <div className="d-flex gap-2 ">
                                {event
                                  ?.filter(
                                    (value) =>
                                      value?.category ===
                                        Categorie[index]?.name &&
                                      value?.enabled === true
                                  )
                                  ?.map((value, index) => (
                                    <div className="d-flex flex-column flex-wrap gap-3 py-4">
                                      <div
                                        key={index}
                                        className="bg_clr_dark_grey_3 sports-games-box rounded-2 d-flex flex-column align-items-center justify-content-center"
                                        onClick={() => {
                                          if (User !== null) {
                                            navigate(
                                              "/casino-player/" +
                                                value.code +
                                                "?fn=" +
                                                value?.fun_mode
                                            );
                                          } else {
                                            setFunModeModal(true);
                                          }
                                        }}
                                      >
                                        {/* <div className=""> */}
                                        <img
                                          className="w-100 object-fit-cover rounded"
                                          src={`https://luckmedia.link/${value.code}/thumb_1_1.webp`}
                                          alt={value.sports}
                                        />
                                        {/* </div> */}
                                      </div>
                                      <div className="d-flex gap-2 ">
                                        <img
                                          src={dot_icon}
                                          alt="dot_icon"
                                          width={8}
                                          height={8}
                                        />
                                        <p className="mb-0 ff_inter fw-medium fs_10 text-white text-nowrap">
                                          {showTwoWordsWithEllipsis(
                                            value?.name
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                );
              }
            )}
          </>
        ) : (
          <div className="container">
            <div className="">
              <div className="d-flex align-items-center gap-2">
                <img width={20} src={slotsIcon} alt="football" />
                <p className="text-white mb-0 ff_inter fw-medium fs_16">
                  Slots
                </p>
              </div>
            </div>
            <div className="container ms-auto overflow-auto px-0 sports-casino-scrollbar">
              <div className="">
                <div className="d-flex  gap-3 pt-4">
                  {event
                    ?.filter(
                      (value) =>
                        value?.category === Categorie[active]?.name &&
                        value?.enabled === true
                    )
                    ?.map((value, index) => (
                      <div className="d-flex flex-column flex-wrap gap-3 py-4">
                        <div
                          key={index}
                          className="bg_clr_dark_grey_3 sports-games-box rounded-2 d-flex flex-column align-items-center justify-content-center"
                          onClick={() => {
                            if (User !== null) {
                              navigate(
                                "/casino-player/" +
                                  value.code +
                                  "?fn=" +
                                  value?.fun_mode
                              );
                            } else {
                              setFunModeModal(true);
                            }
                          }}
                        >
                          {/* <div className=""> */}
                          <img
                            className="w-100 object-fit-cover rounded"
                            src={`https://luckmedia.link/${value.code}/thumb_1_1.webp`}
                            alt={value.sports}
                          />
                          {/* </div> */}
                        </div>
                        <div className="d-flex gap-2 ">
                          <img
                            src={dot_icon}
                            alt="dot_icon"
                            width={8}
                            height={8}
                          />
                          <p className="mb-0 ff_inter fw-medium fs_10 text-white text-nowrap">
                            {showTwoWordsWithEllipsis(value?.name)}
                          </p>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        )
      ) : (
        <div className="d-flex gap-3 px-3 overflow-hidden">
          <div>
            <div
              className="skeleton rounded gameCard"
              style={{ height: "100px", width: "100px" }}
            ></div>
            <div
              className="skeleton rounded gameTitle"
              style={{ height: "10px", width: "100px", marginTop: "12px" }}
            ></div>
            <div
              className="skeleton rounded gameTitle"
              style={{ height: "10px", width: "50px", marginTop: "6px" }}
            ></div>
          </div>
          <div>
            <div
              className="skeleton rounded"
              style={{ height: "100px", width: "100px" }}
            ></div>
            <div
              className="skeleton rounded"
              style={{ height: "10px", width: "100px", marginTop: "12px" }}
            ></div>
            <div
              className="skeleton rounded"
              style={{ height: "10px", width: "50px", marginTop: "6px" }}
            ></div>
          </div>{" "}
          <div>
            <div
              className="skeleton rounded"
              style={{ height: "100px", width: "100px" }}
            ></div>
            <div
              className="skeleton rounded"
              style={{ height: "10px", width: "100px", marginTop: "12px" }}
            ></div>
            <div
              className="skeleton rounded"
              style={{ height: "10px", width: "50px", marginTop: "6px" }}
            ></div>
          </div>
        </div>
      )}
      <Modal
        className="bg_modal"
        show={FunModeModal}
        onHide={() => {
          setFunModeModal(false);
        }}
        centered
      >
        <Modal.Body className="text-center  text-white">
          <div className=" bg_clr_green px-3 py-5 mt-4 rounded-1 position-relative">
            <div
              onClick={() => {
                setFunModeModal(false);
              }}
              className=" position-absolute end-0 top-0"
            >
              <img className="pe-2 pt-2" src={cross_icon} alt="cross_icon" />
            </div>
            <h3 className=" fs_14 fw-medium ff_heebo text-white mt-3 mb-0">
              Fun Mode not Available for this game
              <br />
              <br />
              Please Login to play
            </h3>

            <button
              onClick={() => {
                setFunModeModal(false);
              }}
              className=" px-4 py-2 rounded-2 border-0 ff_heebo fw-semibold mt-4"
            >
              Login
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CasinoSlots;
