import React, { useEffect, useState } from "react";
import { GetCasnioHistory } from "../Services/HistoryServices";
import { getLocalDate, getLocalTime, removeUnwanted } from "../Services/Common";

const BetHistory = () => {
  const [CasnioHistory, setCasnioHistory] = useState([]);
  const [dateRange, setDateRange] = useState({
    startDate: "",
    endDate: "",
    status: "",
  });

  // Common handler for both start date and end date inputs
  const handleDateChange = (event) => {
    const { name, value } = event.target;
    setDateRange((prevState) => ({
      ...prevState, // Keep the other date value
      [name]: value, // Dynamically update the changed input field (startDate or endDate)
    }));
  };

  // Fetch Casino History
  const getCasnioHistory = async () => {
    const data = {
      status: dateRange.status, // credit and debit
      start_date: dateRange.startDate || "",
      end_date: dateRange.endDate || "",
      page: 1,
      limit: 10,
    };
    await GetCasnioHistory(data).then((res) => {
      if (res) {
        setCasnioHistory(res?.data);
        console.log(res?.data);
      }
    });
  };

  useEffect(() => {
    getCasnioHistory(); // Fetch Casino history on date range change
  }, [dateRange]);

  return (
    <div className="container py-3">
      <div className="d-flex justify-content-between align-items-end">
        <h5 className="fs_16 mb-2 pb-0 fw-medium ff_heebo text-white">
          Casino Bet History
        </h5>
        <div className="d-flex gap-3 align-items-end">
          <div className="d-flex flex-column">
            <label className="text-white ff_heebo" htmlFor="">
              Start Date
            </label>
            <input
              className="mt-1 px-2 py-2 rounded-2"
              type="date"
              id="startDate"
              name="startDate"
              value={dateRange.startDate}
              onChange={handleDateChange}
            />
          </div>
          <div className="d-flex flex-column">
            <label className="text-white ff_heebo" htmlFor="">
              End Date
            </label>
            <input
              className="mt-1 px-2 py-2 rounded-2"
              type="date"
              id="endDate"
              name="endDate"
              value={dateRange.endDate}
              onChange={handleDateChange}
            />
          </div>
          <select
            className=" rounded-2 py-2 px-4"
            name="status"
            onChange={handleDateChange}
          >
            <option value="">All</option>
            <option value="credit">Credit</option>
            <option value="debit">Debit</option>
          </select>
        </div>
      </div>
      <div className="mt-3">
        {CasnioHistory.length > 0 ? (
          <div className="my-4">
            {CasnioHistory?.map((item, index) => (
              <div
                key={index}
                className="bg_clr_dark_grey_3 p-3 rounded-1 mt-3"
              >
                <div className="d-flex align-items-center justify-content-between">
                  <div>
                    <h5 className="mb-0 fs_16 ff_heebo fw-medium text-white text-uppercase">
                      {removeUnwanted(item?.game_code)}
                    </h5>
                    <p className="mb-0 fs_11 ff_heebo mt-1 text-white">
                      {getLocalDate(item.createdAt) +
                        " " +
                        `(${getLocalTime(item.createdAt)})`}
                    </p>
                  </div>
                  {item.status === "debit" ? (
                    <span className="fs_16 fw-medium ff_heebo clr_red">
                      - {item?.amount}
                    </span>
                  ) : (
                    <span className="fs_16 fw-medium ff_heebo clr_green">
                      + {item?.amount}
                    </span>
                  )}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div>
            <h4 className="fs_16 fw-medium ff_heebo text-white text-center mt-4">
              No Bet History Found
            </h4>
          </div>
        )}
      </div>
    </div>
  );
};

export default BetHistory;
