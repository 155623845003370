import { Navigate, Outlet, createBrowserRouter } from "react-router-dom";
import MailLogin from "../components/auth/MailLogin";
import OneClickLogin from "../components/auth/OneClickLogin";
import PhoneLogin from "../components/auth/PhoneLogin";
import SocialLogin from "../components/auth/SocialLogin";
import LoginTemplate from "../pages/LoginTemplate";
import MainLayout from "../components/Layout/MainLayout";
import NewHomePage from "../pages/NewHomePage";
import HomePage from "../pages/HomePage";
import IntrestPage from "../pages/IntrestPage";
import CasinoPage from "../pages/CasinoPage";
import CasinoPlayer from "../components/casinopage/CasinoPlayer";
import BetHistoryComponents from "../components/BetHistoryComponents";
import SupportPage from "../pages/SupportPage";
import Withdrawal from "../components/deposit_withdrawal/Withdrawal";
import Deposit from "../components/deposit_withdrawal/Deposit";
import SupportChat from "../components/supportpage/SupportChat";
import ViewAllLiveCasino from "../components/casinopage/ViewAllLiveCasino";
import IntrestDetails from "../components/intrestpage/IntrestDetails";
import Profile from "../components/bottomnav/Profile";
import Matches from "../pages/Matches";
import BettingMain from "../pages/BettingMain";
import SelectedBannerPage from "../pages/SelectedBannerPage";
import OTPVerification from "../components/auth/OTPVerification";
import Transactions from "../pages/History/Transactions";
import Bouns from "../pages/Bouns/Bouns";
import EventDetails from "../components/SportsRadarComponets/EventDetails";
import LoyaltyBouns from "../pages/Bouns/LoyaltyBouns";
import UpomingEvents from "../pages/UpomingEvents";
import Try from "../Services/Try";
import OtherSportsEventDetails from "../components/SportsRadarComponets/OtherSportsEventDetails";
import QRCodeTest from "../components/QRCodeTest";
import DestopMainLayout from "../components/Layout/DestopMainLayout";
import SportsHistory from "../components/SportsHistory";
import BetHistory from "../components/BetHistory";

let DesktopRoutes = "";

const auth = [
  {
    path: "/login",
    element: (
      <>
        <LoginTemplate />
      </>
    ),
    children: [
      {
        index: true,
        element: <Navigate to="one-click-login" replace />,
      },
      {
        path: "one-click-login",
        element: <OneClickLogin />,
      },
      {
        path: "phone-login",
        element: <PhoneLogin />,
      },
      {
        path: "social-login",
        element: <SocialLogin />,
      },
      {
        path: "mail-login",
        element: <MailLogin />,
      },
    ],
  },
  {
    path: "/*",
    element: (
      <>
        <Navigate to="/login/one-click-login" replace />
      </>
    ),
  },
];

const OTP = [
  {
    path: "/otp",
    element: (
      <>
        <Outlet />
      </>
    ),
    children: [
      {
        index: true,
        element: <Navigate to="verifi" replace />,
      },
      {
        path: "verifi/:type",
        element: <OTPVerification />,
      },
    ],
  },
  {
    path: "/*",
    element: (
      <>
        <Navigate to="/" replace />
      </>
    ),
  },
];

const common = [
  {
    path: "/",
    element: (
      <>
        <DestopMainLayout />
      </>
    ),
    children: [
      {
        path: "/",
        element: <NewHomePage />,
      },
      {
        path: "sports",
        element: <HomePage />,
      },
      {
        path: "intrest-page",
        element: <IntrestPage />,
      },
      {
        path: "casino-page",
        element: <CasinoPage />,
      },
      {
        path: "casino-player/:id",
        element: <CasinoPlayer />,
      },
      {
        path: "bet-history",
        element: <BetHistoryComponents />,
      },
      {
        path: "support-page",
        element: <SupportPage />,
      },
      {
        path: "withdrawal",
        element: <Withdrawal />,
      },
      {
        path: "deposit",
        element: <Deposit />,
      },
      {
        path: "view-all-live-casino",
        element: <ViewAllLiveCasino />,
      },
      {
        path: "intrest-details",
        element: <IntrestDetails />,
      },

      {
        path: "Matches/:id",
        element: <Matches />,
      },
      {
        path: "Upcoming/:id",
        element: <UpomingEvents />,
      },
      {
        path: "Betting",
        element: <BettingMain />,
      },
      {
        path: "banner/:selected",
        element: <SelectedBannerPage />,
      },
      {
        path: "bouns",
        element: <Bouns />,
      },
      {
        path: "sport_details/:id/:sport/:date/:eventId",
        element: <EventDetails />,
      },
      {
        path: "sport_details/:id/:sport/",
        element: <OtherSportsEventDetails />,
      },
      {
        path: "try",
        element: <Try />,
      },
      {
        path: "sportsHistory",
        element: <SportsHistory />,
      },
      {
        path: "betHistory",
        element: <BetHistory />,
      },
      {
        path: "qrtest",
        element: <QRCodeTest />,
      },
    ],
  },
  {
    path: "/*",
    element: (
      <>
        <Navigate to="/" replace />
      </>
    ),
  },
];

const Chat = [
  {
    path: "/",
    element: (
      <>
        <Outlet />
      </>
    ),
    children: [
      {
        path: "support-chat",
        element: <SupportChat />,
      },
    ],
  },
];
const PrivateRoutes = [
  {
    path: "/app",
    element: (
      <>
        <DestopMainLayout />
      </>
    ),
    children: [
      {
        path: "transactions",
        element: <Transactions />,
      },
      {
        path: "loyalty-point",
        element: <LoyaltyBouns />,
      },
      {
        path: "Profile",
        element: <Profile />,
      },
    ],
  },
  {
    path: "/*",
    element: (
      <>
        <Navigate to="/" replace />
      </>
    ),
  },
];

if (localStorage.getItem("sfwa_tkn")) {
  DesktopRoutes = createBrowserRouter([...common, ...PrivateRoutes, ...Chat]);
} else {
  DesktopRoutes = createBrowserRouter([...common, ...auth, ...OTP, ...Chat]);
}

export default DesktopRoutes;
