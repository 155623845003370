import React, { useEffect } from "react";
import CasinoHero from "../components/casinopage/CasinoHero";
import AppNavbar from "../components/home/AppNavbar";
import CasinoCategory from "../components/casinopage/CasinoCategory";
import CasinoEvents from "../components/casinopage/CasinoEvents";
import BottomNav from "../components/bottomnav/BottomNav";
import Appfooter from "../components/footer/Appfooter";
import { useDispatch } from "react-redux";
import { GetAllCasnio } from "../redux/Casino/CasinoSlice";
import HomeHero from "../components/home/HomeHero";

const CasinoPage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetAllCasnio());
  }, []);
  useEffect(() => {
    window.scroll("top", 0);
  }, []);
  useEffect(() => {
    const intercom = document.getElementsByClassName(
      "intercom-lightweight-app-launcher"
    );
    for (let i = 0; i < intercom.length; i++) {
      intercom[i].style.display = "block";
    }
  }, []);
  return (
    <div>
      <HomeHero />
      <CasinoEvents />
      <Appfooter />
      {/* <BottomNav /> */}
    </div>
  );
};

export default CasinoPage;
