import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  EmailOtpVerify,
  NumberOtpVerify,
  resentOTP,
} from "../../Services/AuthService";
import { ToastContainer, toast } from "react-toastify";

const OTPVerification = () => {
  const [otp, setOtp] = useState("");
  const inputs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const Number = useSelector((state) => state.User.number);
  const email = useSelector((state) => state.User.email);
  const [message, setMessage] = useState("");
  const { type } = useParams();
  const navigate = useNavigate();
  const handleInputChange = (e, index) => {
    const value = e.target.value;

    if (/^\d$/.test(value)) {
      const newOtp = otp.split("");
      newOtp[index] = value;
      setOtp(newOtp.join(""));

      if (index < inputs.length - 1) {
        inputs[index + 1].current.focus();
      }
    } else if (value === "") {
      const newOtp = otp.split("");
      newOtp[index] = value;
      setOtp(newOtp.join(""));
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && otp[index] === "" && index > 0) {
      inputs[index - 1].current.focus();
    }
  };

  useEffect(() => {
    if (type == "number") {
      if (Number == null) {
        navigate("/login/phone-login");
      }
    } else {
      if (email == null) {
        // navigate("/login/phone-login");
      }
    }
  }, [Number, email]);

  const handleVerify = async () => {
    if (type == "number") {
      if (otp.length == 4) {
        NumberOtpVerify(Number, otp).then((res) => {
          if (res.status) {
            localStorage.setItem("sfwa_tkn", res.token);
            window.location.reload();
          } else {
            // setMessage("Incorrect OTP");
            toast.error("Incorrect OTP");
          }
        });
      } else {
        toast.error("Please Fill 4 digit OTP");
      }
    } else {
      if (otp.length == 4) {
        EmailOtpVerify(email, otp).then((res) => {
          if (res.status) {
            localStorage.setItem("sfwa_tkn", res.token);
            window.location.reload();
          } else {
            toast.error("Incorrect OTP");
          }
        });
      } else {
        toast.error("Please Fill 4 digit OTP");
      }
    }
  };

  const handleResentOTP = async () => {
    if (type == "number") {
      const data = {
        phone: Number,
        type: "phone",
      };
      await resentOTP(data).then((res) => {
        if (res) {
          toast.success("OTP Resent SuccessFully");
        } else {
          toast.error("Error in resending OTP");
        }
      });
    } else {
      const data = {
        email: email,
        type: "email",
      };
      await resentOTP(data).then((res) => {
        if (res) {
          toast.success("OTP Resent SuccessFully");
        } else {
          toast.error("Error in resending OTP");
        }
      });
    }
  };
  useEffect(() => {
    window.scroll("top", 0);
  }, []);
  return (
    <div className="">
      <ToastContainer />
      <div style={{ maxWidth: "600px" }} className="mx-auto">
        <div className="d-flex flex-column justify-content-between px-4 mt-4">
          <div className="pt-4">
            <h3 className="fs_28 fw-bold clr_green ff_roboto">
              OTP Verification
            </h3>
            <p className="fs_16 ff_roboto text-white">
              Enter the OTP we have sent to your phone number
            </p>
            <div className="d-flex justify-content-between gap-3 mt-4">
              {inputs.map((inputRef, index) => (
                <input
                  key={index}
                  type="text"
                  maxLength="1"
                  className="text-center border-0 fs-20 text-white outline_none p-2 rounded-1 bg_clr_dark_grey_3"
                  style={{ width: "60px", height: "60px", fontSize: "30px" }}
                  ref={inputRef}
                  value={otp[index] || ""}
                  onChange={(e) => handleInputChange(e, index)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                />
              ))}
            </div>
            <div className="d-flex justify-content-end mt-2">
              <a className="text-white ff_roboto fs_16" href="#">
                Didn't receive the OTP?
                <span
                  className="fw-medium ff_roboto clr_green px-1"
                  onClick={handleResentOTP}
                >
                  Resend.
                </span>
              </a>
            </div>
            {message && (
              <div className="text-danger my-3 text-center">{message}</div>
            )}
          </div>
          <div className="pb-4 mt-5">
            {/* <Link to="/intrest-page"> */}
            <button
              className="w-100 mt-4 fs_16 text-white bg_clr_green ff_roboto border-0 p-3 rounded-1"
              onClick={handleVerify}
            >
              Continue
            </button>
            {/* </Link> */}
            <button className="w-100 mt-4 fw-bold fs_16 ff_roboto text-white border-0 bg_clr_dark_grey_3 p-3 rounded-1">
              <span className="fw-normal clr_green">Log in with</span> Demo ID
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OTPVerification;
